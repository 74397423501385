import React from 'react';
import { NavLink } from 'react-router-dom';

const Faq = () => (
  <div className="bg-white" id="FAQ">
    <div className="max-w-screen-xl mx-auto pt-12 pb-16 sm:pt-16 sm:pb-20 px-4 sm:px-6 lg:pt-20 lg:pb-28 lg:px-8">
      <h2 className="text-3xl leading-9 font-extrabold text-gray-900">
        Frequently asked questions
      </h2>
      <div className="mt-6 border-t border-gray-200 pt-10">
        <dl className="md:grid md:grid-cols-2 md:gap-8">
          <div>
            <div>
              <dt className="text-lg leading-6 font-medium text-gray-900">
                What kind of investors do you have in this database?
              </dt>
              <dd className="mt-2">
                <p className="text-base leading-6 text-gray-500">
                  Investor Scout has tens of thousands of investors that invest in everything you can imagine: sports, fashion, AI, crypto, biotech, etc. Our investors are active everywhere from the USA to China and Europe to Australia––we have investors on every continent except Antarctica. Our investor database includes angel investors, angel investing groups, venture capital funds, and private equity funds.
                </p>
              </dd>
            </div>
            <div className="mt-12">
              <dt className="text-lg leading-6 font-medium text-gray-900">
              What do I do with the investor emails?
              </dt>
              <dd className="mt-2">
                <p className="text-base leading-6 text-gray-500">
                  You can email each investor one-by-one; or you can add them to your own investor list, then export their emails to CSV. You can then take your CSV and upload to mass emailing software like MixMax or Reply.io.
                </p>
              </dd>
            </div>
            <div className="mt-12">
              <dt className="text-lg leading-6 font-medium text-gray-900">
                What is an investor list?
              </dt>
              <dd className="mt-2">
                <p className="text-base leading-6 text-gray-500">
                  An investor list is simply a list of investors created for a specific purpose. If you’re fundraising for your startup, you can put together a list of a few hundred investors in your field that you think will be interested in investing (based on the interests tags, past investments, and other data we’ve provided for you). When you’re ready to fundraise, you can start reaching out to them!
                </p>
              </dd>
            </div>
            <div className="mt-12">
              <dt className="text-lg leading-6 font-medium text-gray-900">
                How often is this data updated?
              </dt>
              <dd className="mt-2">
                <p className="text-base leading-6 text-gray-500">
                  We make improvements to this data almost everyday, but there are always improvements to be made.
                </p>
              </dd>
            </div>
          </div>
          <div className="mt-12 md:mt-0">
            <div>
              <dt className="text-lg leading-6 font-medium text-gray-900">
                Why can’t I see investor emails?
              </dt>
              <dd className="mt-2">
                <p className="text-base leading-6 text-gray-500">
                  Our basic package doesn’t come with access to investor emails––just their social media links. If you’re on a plan that includes emails, and you don’t see some, it is likely because we don’t have emails on record for that investor. This can be due to many reasons, from GDPR or privacy regulations to them not having their email publicly accessible.
                </p>
              </dd>
            </div>
            <div className="mt-12">
              <dt className="text-lg leading-6 font-medium text-gray-900">
                How did you get this data?
              </dt>
              <dd className="mt-2">
                <p className="text-base leading-6 text-gray-500">
                  We started building this dataset manually a few years ago when we started raising money for new projects. After growing it to over a thousand investors, we decided to build a few bots and compile other datasets to grow this investor database to a list of 37,000+ investors.
                </p>
              </dd>
            </div>
            <div className="mt-12">
              <dt className="text-lg leading-6 font-medium text-gray-900">
                How do I contact these investors?
              </dt>
              <dd className="mt-2">
                <p className="text-base leading-6 text-gray-500">
                  Please don’t spam these investors! Compile a list of relevant investors you think would be interested in your project, then use an email service like Mixmax or Reply.io to email them.
                </p>
              </dd>
            </div>
            <div className="mt-12">
              <dt className="text-lg leading-6 font-medium text-gray-900">
                How many investors do you have?
              </dt>
              <dd className="mt-2">
                <p className="text-base leading-6 text-gray-500">
                  We have over 37,000 investors, and over 36,000 of them have email addresses available. We also have about 33,000 investor phone numbers and 37,000 investor office addresses.
                </p>
              </dd>
            </div>
          </div>
        </dl>
      </div>
    </div>
    <div className="bg-gray-100 border-t border-gray-200">
      <div className="max-w-screen-xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
          Ready to raise your round?
          <br />
          Get started for free today.
        </h2>
        <div className="mt-8 flex justify-center">
          <div className="inline-flex rounded-lg shadow-sm">
            <NavLink to="/signup" className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-bold rounded-lg text-white bg-investor-blue hover:opacity-75 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
              Get started for free
              <svg className="fill-current opacity-50 w-5 h-5 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z" clipRule="evenodd" />
              </svg>
            </NavLink>
          </div>
          <div className="ml-3 inline-flex rounded-lg">
            <NavLink to="/pricing" className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-bold rounded-lg shadow-sm border border-gray-200 text-gray-900 bg-white focus:outline-none focus:shadow-outline focus:border-indigo-300 transition duration-150 ease-in-out">
              See pricing
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Faq;

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import { loadStripe } from '@stripe/stripe-js';
import Firebase from 'firebase/app';
const stripeAPIKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const stripePromise = loadStripe(stripeAPIKey);

class StripeBillingPortal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      redirect: false,
      url: "",
    };
    this.launchPortal = this.launchPortal.bind(this);
  }

  launchPortal = async (uid, stripeCustomerId, email) => {
    console.log(uid)
    console.log(stripeCustomerId)
    console.log(email)
    this.setState({
      isLoading: true,
    })
    var fetchBillingPortalSession = Firebase.functions().httpsCallable('fetchBillingPortalSession');
    var data = { uid, stripeCustomerId, email };

    const res = await fetchBillingPortalSession(data);
    console.log(res)
    console.log(res.data.session.url)
    if (res.data.session.url) {
      this.setState({
        url: res.data.session.url,
        redirect: true,
      });
    };
  }

  renderRedirect = () => {
    if (this.state.redirect) {
      window.location.assign(this.state.url);
    }
  }

  render() {
    const { stripeCustomerId, email, uid, pricing } = this.props;
    const { isLoading }  = this.state
    return (
      <div>
        {this.renderRedirect()}
        {pricing ? (
          <button
            className="block w-full text-center rounded-lg border border-transparent bg-investor-blue px-6 py-3 text-base leading-6 font-bold text-white hover:opacity-75 focus:outline-none focus:shadow-outline transition ease-in-out duration-150 shadow-sm"
            onClick={ () => this.launchPortal(uid, stripeCustomerId, email)}
          >
            Upgrade now!
          </button>
        ) : (!pricing && isLoading) ? (
          <button
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-1000 hover:opacity-75 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
          >
            Manage billing
          </button>
        ) : (
          <button
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-1000 hover:opacity-75 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
            onClick={ () => this.launchPortal(uid, stripeCustomerId, email) }
          >
            Manage billing
          </button>
        ) }
      </div>
    );
  }
}

export default StripeBillingPortal;

import React, { Component } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import Firebase from 'firebase/app';
const stripeAPIKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const stripePromise = loadStripe(stripeAPIKey);

const stripePlanIds = {
  "basicMonthly": "price_1HWYbpBcgiVkcpN54Uei8IDR",
  "startupMonthly": "price_1HWZHKBcgiVkcpN5mDVEidLM",
  "premiumMonthly": "price_1HWZHtBcgiVkcpN5qKoHL5iG",
  "proMonthly": "price_1HWZIhBcgiVkcpN5jv2dsFW5"
}

const firestorePlanIds = {
  "basicMonthly": 1,
  "startupMonthly": 2,
  "premiumMonthly": 3,
  "proMonthly": 4
}

class StripeCheckout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      referral: '',
    };
    this.handleCheckout = this.handleCheckout.bind(this);
  }

  componentDidMount() {
    window.rewardful('ready', () => {
      if (window.Rewardful.referral) {
        this.setState({ referral: window.Rewardful.referral });
      }
    })
  }

  handleCheckout = async (uid, planId, stripeCustomerId, email, plan) => {
    var referral = this.state.referral;
    console.log(plan)
    console.log(uid)
    console.log(planId)
    console.log(email)
    console.log(referral)
    this.setState({
      isLoading: true,
    })
    var fetchCheckoutSession = Firebase.functions().httpsCallable('fetchCheckoutSession');
    var data = { uid, planId, stripeCustomerId, email, plan, referral };

    const res = await fetchCheckoutSession(data);
    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      sessionId: res.data.sessionId,
    });
    if (error) {
      alert(error.message);
    }
  }

  render() {
    const { stripeCustomerId, email, uid } = this.props;
    const planId = stripePlanIds[this.props.plan];
    const plan = firestorePlanIds[this.props.plan]
    return (
      !this.state.isLoading ? (
        <button className="block w-full text-center rounded-lg border border-transparent bg-white px-6 py-3 text-base leading-6 font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:shadow-outline transition ease-in-out duration-150" onClick={ () => this.handleCheckout(uid, planId, stripeCustomerId, email, plan) }>Buy now!</button>
      ) : ( <button className="block w-full text-center rounded-lg border border-transparent bg-white px-6 py-3 text-base leading-6 font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:shadow-outline transition ease-in-out duration-150">Buy now!</button> )
    );
  }
}

export default StripeCheckout;

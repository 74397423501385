import React from 'react'
import { NavLink } from 'react-router-dom';

const Error = ({ error }) => (
  <div>
    <NavLink to="/">
      <div className="h-screen w-screen bg-green-600 flex justify-center content-center flex-wrap">
        <p className="block text-5xl text-white">404</p>
        <p className="block text-md text-white">{error ? error.message : null}</p>
      </div>
    </NavLink>
  </div>
);

export default Error;

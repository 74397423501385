import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import Transition from '../../styles/Transition.js';

import Logo from '../../assets/investor-scout-3-logo-full-white.svg';
import Logosq from '../../assets/investor-scout-3-logo-full.svg';
export default function LandingNav() {

  const [mobileDropdownOn, setMobileDropdown] = useState(false);

  return (
		<div>
		  <nav className="relative max-w-screen-xl mx-auto flex items-center justify-between px-4 sm:px-6">
		    <div className="flex items-center flex-1">
		      <div className="flex items-center justify-between w-full md:w-auto">
		        <NavLink to="/">
		          <img className="hidden lg:block h-10 w-auto" src={Logo} alt="Investor Scout logo" />
		        </NavLink>
		      </div>
		      <div className="hidden space-x-10 md:flex md:ml-10">
		        <NavLink to="/pricing" className="flex text-lg font-medium text-white text-opacity-75 hover:text-white transition duration-150 ease-in-out">
		          <svg className="fill-current opacity-25 w-5 h-5 mr-2 mt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
		            <path d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z" />
		            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z" clipRule="evenodd" />
		          </svg>
		          Pricing
		        </NavLink>
		        <a href="#testimonials" className="flex text-lg font-medium text-white text-opacity-75 hover:text-white transition duration-150 ease-in-out">
		          <svg className="fill-current opacity-25 w-5 h-5 mr-2 mt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
		            <path fillRule="evenodd" d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" clipRule="evenodd" />
		          </svg>
		          Testimonials
		        </a>
		        <a href="#FAQ" className="flex text-lg font-medium text-white text-opacity-75 hover:text-white transition duration-150 ease-in-out">
		          <svg className="fill-current opacity-25 w-5 h-5 mr-2 mt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
		            <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
		          </svg>
		          FAQ
		        </a>
		      </div>
		    </div>
		    <div className="hidden md:flex">
		      <NavLink to="/signin" className="inline-flex items-center px-4 py-2 border border-gray-200 text-sm shadow-sm leading-5 font-bold rounded-lg text-gray-800 bg-white focus:outline-none focus:shadow-outline-gray focus:border-gray-700 active:bg-gray-700 hover:opacity-75 transition duration-150 ease-in-out">
		        Log in
		        <svg className="fill-current opacity-50 w-4 h-4 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
		          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z" clipRule="evenodd" />
		        </svg>
		      </NavLink>
		    </div>
		    <div className="-mr-2 flex items-center sm:hidden">
		      <button onClick={() => setMobileDropdown(!mobileDropdownOn)} className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
		        <svg className={`${mobileDropdownOn ? 'hidden' : 'inline'} h-6 w-6`} stroke="currentColor" fill="none" viewBox="0 0 24 24">
		          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
		        </svg>
		        <svg className={`${mobileDropdownOn ? 'inline' : 'hidden'} h-6 w-6`} stroke="currentColor" fill="none" viewBox="0 0 24 24">
		          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
		        </svg>
		      </button>
		    </div>
		  </nav>

		  <Transition
		    show={mobileDropdownOn}
		    enter="transition ease-out duration-150"
		    enterFrom="transform opacity-0 scale-95"
		    enterTo="transform opacity-100 scale-100"
		    leave="transition ease-in duration-100"
		    leaveFrom="transform opacity-100 scale-100"
		    leaveTo="transform opacity-0 scale-95"
		  >
		    <div className={`${mobileDropdownOn ? 'inline' : 'hidden'} sm:hidden top-0 inset-x-0 p-2 transition transform origin-top-right`}>
		      <div className="rounded-lg shadow-md mx-5">
		        <div className="rounded-lg bg-white shadow-xs overflow-hidden" role="menu" aria-orientation="vertical" aria-labelledby="main-menu">
		          <div className="px-5 pt-4 flex items-center justify-between border-b border-gray-200 pb-4">
		            <div>
		              <img className="h-8 w-auto" src={Logosq} alt="" />
		            </div>
		          </div>
		          <div className="space-y-1 px-2 pt-6 pb-3">
		          <NavLink to="/pricing" className="flex text-xl font-medium text-gray-900 text-opacity-75 hover:text-gray-800 pl-4 pb-2 transition duration-150 ease-in-out">
		            <svg className="fill-current opacity-25 w-5 h-5 mr-2 mt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
		              <path d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z" />
		              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z" clipRule="evenodd" />
		            </svg>
		            Pricing
		          </NavLink>
		          <a href="#testimonials" className="flex text-xl font-medium text-gray-900 text-opacity-75 hover:text-gray-800 pl-4 pb-2 transition duration-150 ease-in-out">
		            <svg className="fill-current opacity-25 w-5 h-5 mr-2 mt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
		              <path fillRule="evenodd" d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" clipRule="evenodd" />
		            </svg>
		            Testimonials
		          </a>
		          <a href="#FAQ" className="flex text-xl font-medium text-gray-900 text-opacity-75 hover:text-gray-800 pl-4 transition duration-150 ease-in-out">
		            <svg className="fill-current opacity-25 w-5 h-5 mr-2 mt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
		              <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
		            </svg>
		            FAQ
		          </a>
		          </div>
		          <div>
		            <NavLink to="/signin" className="block w-full px-5 py-3 text-center font-bold text-gray-900 bg-gray-50 hover:opacity-75 hover:text-gray-900 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out" role="menuitem">
		              Log in
		            </NavLink>
		          </div>
		        </div>
		      </div>
		    </div>
		  </Transition>
		</div>
	)
}
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Firebase from 'firebase/app';
import Transition from '../../styles/Transition.js';

class AddAllToListButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saved: false,
      addToListDropdownOn: false,
      fundIndex: [],
      investorIndex: [],
      plan: this.props.plan,
      lists: this.props.lists,
      status: this.props.status,
      dataType: this.props.dataType,
      error: false,
      isLoading: false,
    };
    this.addFundsToList = this.addFundsToList.bind(this);
    this.addInvestorsToList = this.addInvestorsToList.bind(this);
    this.toggleAddToListDropdown = this.toggleAddToListDropdown.bind(this);
  }

  toggleAddToListDropdown() {
    this.setState(prevState => ({
      addToListDropdownOn: !prevState.addToListDropdownOn,
    }));
  }

  async addInvestorsToList(key, profiles, plan) {
    console.log("Adding investor");

    var docRef = Firebase.firestore().collection("lists").doc(key);
    await docRef.get().then(doc => {
      this.setState({
        investorIndex: doc.data().investorIndex || [],
      })
    })
    let investorIndex = this.state.investorIndex;
    await docRef.collection("entries").get().then(snap => {
      if (snap.size > 2000) {
        return alert("You've reached limit. Please contact support!");
      }
      if (snap.size > 200 && plan !== 4) {
        return alert("You reached your limit of 200 entries!");
      }
      else {
        Promise.all(profiles.map((profile, index) => {
          this.setState({ isLoading: true, addToListDropdownOn: false });
          if (profile.objectID && investorIndex.includes(profile.objectID)) {
            return;
          } else {
            profile["counter"] = snap.size + 1;
            profile["dataType"] = "Investor";
            profile["nameToOrderBy"] = profile.contactFirmName || profile.contactLastName;
            profile["addedOn"] = Firebase.firestore.Timestamp.now();
            investorIndex.push(profile.objectID)
            return docRef.collection("entries").add(profile)
          }
        }))
        .then(() => {
          docRef.update({
            investorIndex,
            updatedOn: Firebase.firestore.Timestamp.now()
          });
          this.setState({ isLoading: false, saved: true });
          setTimeout(() => {
            this.setState({ saved: false });
          }, 1500);
        });
      };
    });
  }

  async addFundsToList(key, profiles, plan) {
    console.log("Adding fund");

    var docRef = Firebase.firestore().collection("lists").doc(key);
    await docRef.get().then(doc => {
      this.setState({
        fundIndex: doc.data().fundIndex || [],
      })
    })
    let fundIndex = this.state.fundIndex;
    await docRef.collection("entries").get().then(snap => {
      if (snap.size > 2000) {
        return alert("You've reached limit. Please contact support!");
      }
      if (snap.size > 200 && plan !== 4) {
        return alert("You reached your limit of 200 entries!");
      }
      else {
        Promise.all(profiles.map((profile, index) => {
          this.setState({ isLoading: true, addToListDropdownOn: false });
          if (profile.objectID && fundIndex.includes(profile.objectID)) {
            return;
          } else {
            profile["counter"] = snap.size + 1;
            profile["dataType"] = "Fund";
            profile["nameToOrderBy"] = profile.officeFirmName || profile.name;
            profile["addedOn"] = Firebase.firestore.Timestamp.now();
            fundIndex.push(profile.objectID)
            return docRef.collection("entries").add(profile)
          }
        }))
        .then(() => {
          docRef.update({
            fundIndex,
            updatedOn: Firebase.firestore.Timestamp.now()
          });
          this.setState({ isLoading: false, saved: true });
          setTimeout(() => {
            this.setState({ saved: false });
          }, 1500);
        });
      };
    });
  }

  render() {
    const { plan, status, dataType, error } = this.state
    console.log(status)

    return (
      <div>
        <div className="flex-shrink-0">
          { ( status !== "active" || plan === 1 || plan === 10 || plan === 2 || plan === 20) ? (
            <NavLink
              to="/pricing"
              type="button"
              className="text-base bg-blue-1000 hover:opacity-75 text-white font-semibold py-2 px-4 border border-gray-200 rounded-lg shadow-sm transition ease-in-out duration-150 inline-flex items-center"
            >
              <svg className="fill-current opacity-50 w-5 h-5 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path d="M9.707 7.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L13 8.586V5h3a2 2 0 012 2v5a2 2 0 01-2 2H8a2 2 0 01-2-2V7a2 2 0 012-2h3v3.586L9.707 7.293zM11 3a1 1 0 112 0v2h-2V3z" />
                <path d="M4 9a2 2 0 00-2 2v5a2 2 0 002 2h8a2 2 0 002-2H4V9z" />
              </svg>
              Save page
              <svg className="opacity-50 ml-2 h-5 w-5 flex" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
              </svg>
            </NavLink>
          ) : (
            <button
              type="button"
              onClick={() => this.toggleAddToListDropdown(!this.state.addToListDropdownOn)}
              className={`${this.state.saved ? 'text-base bg-green-500 hover:bg-green-400 cursor-wait' : error ? 'text-base bg-red-500 hover:bg-red-400 cursor-wait' : 'border-investor-blue text-base bg-blue-1000 hover:opacity-75 text-white font-semibold py-2 px-4 border border-gray-200 rounded-lg shadow-sm transition ease-in-out duration-150 inline-flex items-center'} relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700`}
            >
              <div>
              { this.state.isLoading ? (
                <div><span>Saving…</span></div>
              ) : this.state.saved ? (
                <div><span>Saved!</span></div>
              ) : (
                <div>
                  <svg className="fill-current opacity-50 w-5 h-5 mr-2 inline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M9.707 7.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L13 8.586V5h3a2 2 0 012 2v5a2 2 0 01-2 2H8a2 2 0 01-2-2V7a2 2 0 012-2h3v3.586L9.707 7.293zM11 3a1 1 0 112 0v2h-2V3z" />
                    <path d="M4 9a2 2 0 00-2 2v5a2 2 0 002 2h8a2 2 0 002-2H4V9z" />
                  </svg>
                  <span>Save page</span>
                  <svg className="opacity-50 ml-2 h-5 w-5 inline" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </div>
              ) }
              </div>
            </button>
          ) }
        </div>
        <Transition
          show={this.state.addToListDropdownOn}
          enter="transition ease-out duration-200"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div className={`${this.state.addToListDropdownOn ? 'block' : 'hidden'} z-10 absolute mt-2 ml-4 w-auto rounded-md shadow-lg`}>
            <div className="rounded-md bg-white shadow-xs">
              <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
              {this.props.lists.map((list, index) => (
                <div
                  key={index}
                  onClick={() => {dataType === "fund" ? this.addFundsToList(list.id, this.props.profiles, plan) : this.addInvestorsToList(list.id, this.props.profiles, plan)}}
                  className="block cursor-pointer px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                  role="menuitem"
                >
                  <i className="fas fa-list heaven-page-dropdown-item-icon" />
                  {list.name}
                </div>
              ))}
              </div>
            </div>
          </div>
        </Transition>
      </div>
    )
  }
}

export default AddAllToListButton;

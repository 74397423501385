import React from 'react';
import { NavLink } from 'react-router-dom';

import StripeBillingPortal from '../subscriptions/StripeBillingPortal';
import FirebaseUser from '../misc/FirebaseUser';
import SubscriptionPlan from '../misc/SubscriptionPlan';

class AccountDash extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: this.props.auth,
      subscription: this.props.subscription
    };
  }

  render() {
    const { auth, subscription } = this.state;

    return (
      <div>
        <div className="max-w-7xl mx-auto mt-20 px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto">
            <div className="bg-white overflow-hidden shadow rounded-lg">
              <div className="px-4 py-5 sm:p-6">
               <h2 className="text-2xl leading-9 font-extrabold text-gray-900">
                  {auth.displayName ? auth.displayName : ""}
                </h2>
                <p className="text-md leading-6 font-bold text-gray-900 mb-3">
                  <span>{auth.email ? auth.email : ""}</span>
                  {(subscription && subscription.stripeSubscriptionId === "appsumo") ? <span className="inline-flex items-center mx-2 px-5 py-1 rounded-full text-sm font-medium bg-yellow-200 text-yellow-800">AppSumo</span> : <span />}
                </p>
                <SubscriptionPlan />
                { (subscription && subscription.stripeSubscriptionId === "appsumo" && subscription.plan === 4) ? (
                    <span /> 
                  ) : (subscription && subscription.stripeSubscriptionId === "appsumo" && subscription.plan === 2) ? (
                  <div className="mt-2 sm:flex sm:items-start sm:justify-between">
                    <div className="max-w-xl text-sm leading-5 text-gray-500">
                      <p>
                        Please enter your AppSumo code to upgrade to Pro Plan
                      </p>
                    </div>
                    <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
                      <span className="inline-flex rounded-md shadow-sm">
                        <NavLink to="appsumo-upgrade">
                          <button className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-1000 hover:opacity-75 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">Upgrade</button>
                        </NavLink>
                      </span>
                    </div>
                  </div>                   
                ) : (
                  <div className="mt-2 sm:flex sm:items-start sm:justify-between">
                    <div className="max-w-xl text-sm leading-5 text-gray-500">
                      <p>
                        Upgrade or downgrade your plan, view your invoices, and update your payment method.
                      </p>
                    </div>
                    <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
                      <span className="inline-flex rounded-md shadow-sm">
                        <FirebaseUser>
                          { ({ isLoading, error, auth, stripeCustomerId, email, subscribed }) => {
                            if (isLoading)
                              return <button className="inline-flex items-center px-4 py-2 text-base leading-5 font-medium rounded-md text-white bg-investor-blue hover:opacity-50 focus:outline-none transition ease-in-out duration-150 active:bg-investor-blue">Manage billing</button>
                            if (subscribed)
                              return <StripeBillingPortal stripeCustomerId={stripeCustomerId} email={email} uid={auth.uid} />
                            return <NavLink to="/pricing" className="inline-flex items-center px-4 py-2 text-base leading-5 font-medium rounded-md text-white bg-investor-blue hover:opacity-50 focus:outline-none transition ease-in-out duration-150 active:bg-investor-blue">Manage billing</NavLink>
                          }}
                        </FirebaseUser>
                      </span>
                    </div>
                  </div> 
                ) }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AccountDash;

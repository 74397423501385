import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import Transition from '../../styles/Transition.js';
import AddToListButton from './addToListButton';

export default function FundResults({ hit, lists, status, plan }) {

  const [infoDropdownOn, setInfoDropdown] = useState(false);
	const [contactDropdownOn, setContactDropdown] = useState(false);

  return (
		<div className="bg-white mb-10 overflow-hidden sm:rounded-lg shadow-sm border border-gray-200">
			<div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6 mb-6">
			  <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-no-wrap">
			    <div className="ml-4 mt-4">
				    <h3 className="text-lg leading-6 font-bold text-gray-900">
				      {hit.officeFirmName}
				    </h3>
				    <div className="mt-1 max-w-2xl text-sm leading-5 text-gray-600">
				      {hit.typeOfFirm} in {hit.officeCity}, {hit.officeState}{hit.officeState ? (",") : <span />} {hit.officeCountry}
				    </div>
			    </div>
			    <div className="relative">
				    <div className="ml-4 mt-4 flex-shrink-0">
				      <span className="inline-flex rounded-md shadow-sm">
                <button type="button" onClick={() => setInfoDropdown(!infoDropdownOn)} className="text-sm bg-white hover:text-gray-900 text-gray-700 font-semibold py-2 px-3 border border-gray-200 rounded-lg shadow-sm transition ease-in-out duration-150 inline-flex items-center">
                  <svg className="fill-current opacity-50 h-5 w-5 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M4.083 9h1.946c.089-1.546.383-2.97.837-4.118A6.004 6.004 0 004.083 9zM10 2a8 8 0 100 16 8 8 0 000-16zm0 2c-.076 0-.232.032-.465.262-.238.234-.497.623-.737 1.182-.389.907-.673 2.142-.766 3.556h3.936c-.093-1.414-.377-2.649-.766-3.556-.24-.56-.5-.948-.737-1.182C10.232 4.032 10.076 4 10 4zm3.971 5c-.089-1.546-.383-2.97-.837-4.118A6.004 6.004 0 0115.917 9h-1.946zm-2.003 2H8.032c.093 1.414.377 2.649.766 3.556.24.56.5.948.737 1.182.233.23.389.262.465.262.076 0 .232-.032.465-.262.238-.234.498-.623.737-1.182.389-.907.673-2.142.766-3.556zm1.166 4.118c.454-1.147.748-2.572.837-4.118h1.946a6.004 6.004 0 01-2.783 4.118zm-6.268 0C6.412 13.97 6.118 12.546 6.03 11H4.083a6.004 6.004 0 002.783 4.118z" clipRule="evenodd" />
                  </svg>
                  About
                  <svg className="opacity-50 ml-2 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </button>
				      </span>
				    </div>
		        <Transition
		          show={infoDropdownOn}
		          enter="transition ease-out duration-200"
		          enterFrom="transform opacity-0 scale-95"
		          enterTo="transform opacity-100 scale-100"
		          leave="transition ease-in duration-75"
		          leaveFrom="transform opacity-100 scale-100"
		          leaveTo="transform opacity-0 scale-95"
		        >
						  <div className={`${infoDropdownOn ? 'block' : 'hidden'} origin-top-right z-10 absolute right-0 mt-2 w-56 rounded-md shadow-lg`}>
						    <div className="rounded-md bg-white shadow-xs" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
						      <div className="px-4 py-3">
						        <div className="text-sm leading-5 text-gray-600">
						          About
						        </div>
						        <div className="text-sm leading-5 font-medium">
						          <span>{hit.numberOfOffices} office(s) · </span>
						          <span>{hit.numberOfInvestors} investor(s) · </span>
						          <span>founded in {hit.yearFounded} · </span>
						          <span>{hit.genderRatio}</span>
						        </div>
						      </div>
						      <div className="border-t border-gray-100" />
						      <div className="px-4 py-3">
						        <div className="text-sm leading-5 text-gray-600">
						          Financing Type
						        </div>
						        <div className="text-sm leading-5 font-medium">
        	        	{(hit.typeOfFinancing && Array.isArray(hit.typeOfFinancing)) ? (
					        		<span>{hit.typeOfFinancing.join(', ')}</span>
					        	) : <span /> }
						        </div>
						      </div>
						      <div className="border-t border-gray-100" />
						      <div className="px-4 py-3">
						        <div className="text-sm leading-5 text-gray-600">
						          Headquarters
						        </div>
						        {(status === "active" && (plan === 3 || plan === 30 || plan === 4)) ? (
							        <div className="text-sm leading-5 font-medium">
							          <span>{hit.officeAddress1}</span><br />
							          {hit.officeAddress2 ? (<p><span>{hit.officeAddress2}</span><br /></p>) : <span />}
							          <span>{hit.officeCity}, </span>
							          <span>{hit.officeState}, </span>
							          <span>{hit.officeZip}</span><br />
							          <span>{hit.officeCountry}</span>
							        </div>
							      ) : (
							      	<NavLink to="/pricing" className="text-sm leading-5 font-medium underline">
							      		<span>Upgrade to access</span>
							      	</NavLink>
							      ) }
						      </div>
						    </div>
						  </div>
						</Transition>
					</div>
			  </div>
			</div>

      {hit.capitalManaged === 0 && hit.minInvestment === 0 && hit.maxInvestment === 0 ? (
        <span />
			) : (
        <div className="mx-4 mt-0 mb-6 sm:mx-6 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow-sm border border-gray-200 md:grid-cols-3">
          <div>
            <div className="px-4 py-5 sm:p-6">
              <dl>
                <dt className="text-sm leading-6 font-normal text-gray-500">
                  AUM
                </dt>
                <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                  <div className="flex items-baseline lg:text-2xl leading-8 font-semibold text-gray-800">
                    {hit.capitalManaged === 0 ? "N/A" : hit.currency + hit.capitalManaged}
                  </div>
                </dd>
              </dl>
            </div>
          </div>
          <div className="border-t border-gray-200 md:border-0 md:border-l">
            <div className="px-4 py-5 sm:p-6">
              <dl>
                <dt className="text-sm leading-6 font-normal text-gray-500">
                  MIN INVESTMENT
                </dt>
                <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                  <div className="flex items-baseline lg:text-2xl leading-8 font-semibold text-gray-800">
                    {hit.minInvestment === 0 ? "N/A" : hit.currency + hit.minInvestment}
                  </div>
                </dd>
              </dl>
            </div>
          </div>
          <div className="border-t border-gray-200 md:border-0 md:border-l">
            <div className="px-4 py-5 sm:p-6">
              <dl>
                <dt className="text-sm leading-6 font-normal text-gray-500">
                  MAX INVESTMENT
                </dt>
                <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                  <div className="flex items-baseline lg:text-2xl leading-8 font-semibold text-gray-800">
                    {hit.maxInvestment === 0 ? "N/A" : hit.currency + hit.maxInvestment}
                  </div>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      )}

		  <div className="px-4 py-2 mb-2 sm:px-6">
		    <dl className="grid grid-cols-1 col-gap-4 row-gap-6 sm:grid-cols-2">
		      <div className="sm:col-span-2">
		        <dt className="text-sm leading-5 font-medium text-gray-500">
		          Geographic Preferences
		        </dt>
		        <dd className="mt-1 text-sm leading-5 text-gray-900">
	        	{(hit.geographicPreferences && Array.isArray(hit.geographicPreferences)) ? (
	        		<span>{hit.geographicPreferences.join(', ')}</span>
	        	) : <span /> }
	        	</dd>
		      </div>
		      <div className="sm:col-span-2">
		        <dt className="text-sm leading-5 font-medium text-gray-500">
		          Stage Preferences
		        </dt>
		        <dd className="mt-1 text-sm leading-5 text-gray-900">
	        	{(hit.stagePreferences && Array.isArray(hit.stagePreferences)) ? (
	        		<span>{hit.stagePreferences.join(', ')}</span>
	        	) : <span /> }
	        	</dd>
		      </div>
		      <div className="sm:col-span-2">
		        <dt className="text-sm leading-5 font-medium text-gray-500">
		          Industry Preferences
		        </dt>
		        <dd className="mt-1 text-sm leading-5 text-gray-900">
	        	{(hit.industryPreferences && Array.isArray(hit.industryPreferences)) ? (
	        		<span>{hit.industryPreferences.join(', ')}</span>
	        	) : <span /> }
	        	</dd>
		      </div>
		    </dl>
		  </div>

			<div className="bg-white px-4 py-6 border-t mt-4 border-gray-200 sm:px-6">
			  <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-no-wrap">
			    <div>
				    <div className="ml-4 mt-4 flex-shrink-0">
              <button type="button" onClick={() => setContactDropdown(!contactDropdownOn)} className="text-sm bg-white hover:text-gray-900 text-gray-700 font-semibold py-2 px-3 border border-gray-200 rounded-lg shadow-sm transition ease-in-out duration-150 inline-flex items-center">
                <svg className="opacity-50 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                </svg>
                Contact
                <svg className="opacity-50 ml-2 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </button>
				    </div>
		        <Transition
		          show={contactDropdownOn}
		          enter="transition ease-out duration-200"
		          enterFrom="transform opacity-0 scale-95"
		          enterTo="transform opacity-100 scale-100"
		          leave="transition ease-in duration-75"
		          leaveFrom="transform opacity-100 scale-100"
		          leaveTo="transform opacity-0 scale-95"
		        >
						  <div className={`${contactDropdownOn ? 'block' : 'hidden'} z-100 absolute mt-2 ml-4 w-auto rounded-md shadow-lg`}>
						    <div className="rounded-md bg-white shadow-xs" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
						      <div className="px-4 py-3">
						        <div className="text-sm leading-5 font-medium">
						          <div className="flex">
						          	<svg className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
													<path strokeLinecap="round" strokeLinejoin="round" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
												</svg>
												&nbsp;&nbsp;
							          <span>{(status !== "active" || plan === 1 || plan === 10) ? "Upgrade to access" : hit.officeEmail}</span>
						          </div>
						          <div className="flex">
							          <svg className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
												  <path strokeLinecap="round" strokeLinejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
												</svg>
												&nbsp;&nbsp;
							          <span>{(status === "active" && (plan === 3 || plan === 30 || plan === 4)) ? hit.officePhone : "Upgrade to access"}</span>
							         </div>
							        <div className="flex">
								        <svg className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
												  <path strokeLinecap="round" strokeLinejoin="round" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
												</svg>
												&nbsp;&nbsp;
							          <span>{hit.officeWebsite}</span>
							         </div>
						        </div>
						      </div>
						      <div className="border-t border-gray-100" />
						      {(hit.officeFirstName || hit.officeWorkEmail || hit.officeWorkPhone) ? (
							      <div className="px-4 py-3">
							        <div className="text-sm leading-5 text-gray-600">
							          Office Contact
							        </div>
							        <div className="text-sm leading-5 font-medium">
							          <span>{hit.officeFirstName} {hit.officeLastName}</span><br />
							          <div className="flex">
							          	<svg className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
														<path strokeLinecap="round" strokeLinejoin="round" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
													</svg>
													&nbsp;&nbsp;
								          <span>{(status !== "active" || plan === 1 || plan === 10) ? "Upgrade to access" : hit.officeWorkEmail}</span>
							         	</div>
							         	{hit.officeWorkPhone ? (
 							          <div className="flex">
								          <svg className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
													  <path strokeLinecap="round" strokeLinejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
													</svg>
													&nbsp;&nbsp;
								          <span>{(status === "active" && (plan === 3 || plan === 30 || plan === 4)) ? hit.officeWorkPhone : "Upgrade to access"}</span>
								        </div> ) : <span /> }
							        </div>
							      </div>
							    ) : <span /> }
						    </div>
						  </div>
						</Transition>
					</div>
					<div>
						{(lists.length > 0 && status === "active") ? (
							<AddToListButton lists={lists} profile={hit} plan={plan} status={status} dataType="fund" />
						) : (lists.length < 1 && status === "active") ? (
							<div className="ml-4 mt-4 flex-shrink-0">
								<NavLink
		              to="lists"
		              type="button"
		              className="ml-3 text-sm bg-blue-1000 hover:opacity-75 text-white font-semibold py-2 px-3 border border-gray-200 rounded-lg shadow-sm transition ease-in-out duration-150 inline-flex items-center"
		            >
		              <svg className="opacity-50 mr-2 h-5 w-5 flex" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
		                <path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" />
		              </svg>
		              No list yet
		              <svg className="opacity-50 ml-2 h-5 w-5 flex" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
		                <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
		              </svg>
		            </NavLink>
		          </div>
		        ) : (
							<div className="ml-4 mt-4 flex-shrink-0">
								<NavLink
		              to="/pricing"
		              type="button"
		              className="ml-3 text-sm bg-blue-1000 hover:opacity-75 text-white font-semibold py-2 px-3 border border-gray-200 rounded-lg shadow-sm transition ease-in-out duration-150 inline-flex items-center"
		            >
		              <svg className="opacity-50 mr-2 h-5 w-5 flex" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
		                <path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" />
		              </svg>
		              Save
		              <svg className="opacity-50 ml-2 h-5 w-5 flex" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
		                <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
		              </svg>
		            </NavLink>
		          </div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

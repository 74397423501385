import Firebase from 'firebase/app';
import slugify from 'slugify';
import { prepareDocForCreate } from './helpers/firestoreHelpers';

const createDefaultList = (authUser, plan)  => {
	let values = {};
	values.name = "Investor List";
	values.slug = "investor-list";
	values.user = authUser.uid;
  values.versionThree = true;
  return Firebase.firestore()
    .collection(`lists`)
    .add(prepareDocForCreate(values))
    .then( () => values)
    .catch( error => {
      alert(`Whoops, couldn't create the list: ${error.message}`)
    })
}

export default createDefaultList;

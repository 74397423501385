import React from 'react';
import { RefinementList } from 'react-instantsearch-dom';

const InvestorFilters = ({ locked }) => (
  <div className="border border-gray-200 bg-white rounded-lg shadow-sm">
    <div className="heaven-search-filter-section">
      <div className="text-base px-4 pt-4 pb-2 font-semibold flex">
        <svg className="h-5 w-5 opacity-50 text-gray-600 mr-2" viewBox="0 0 20 20" fill="currentColor">
          <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
          <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
        </svg>
        <span>Has email address</span>
      </div>
      <RefinementList
        attribute="hasEmail"
        className="text-sm"
      />
    </div>
    <div className="heaven-search-filter-section">
      <div className="text-base px-4 pt-4 pb-2 font-semibold flex">
        <svg className="h-5 w-5 opacity-50 text-gray-600 mr-2" viewBox="0 0 20 20" fill="currentColor">
          <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
        </svg>
        <span>Has phone number</span>
      </div>
      <RefinementList
        attribute="hasPhone"
        className="text-sm"
      />
    </div>
    <div className="heaven-search-filter-section">
      <div className="text-base px-4 pt-4 pb-2 font-semibold flex">
        <svg className="h-5 w-5 opacity-50 text-gray-600 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z" clipRule="evenodd" />
        </svg>
        <span>Has address</span>
      </div>
      <RefinementList
        attribute="hasOfficeAddress"
        className="text-sm"
      />
    </div>
    <div className="heaven-search-filter-section">
      <div className="text-base px-4 pt-4 pb-2 font-semibold flex">
        <svg className="h-5 w-5 opacity-50 text-gray-600 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM4.332 8.027a6.012 6.012 0 011.912-2.706C6.512 5.73 6.974 6 7.5 6A1.5 1.5 0 019 7.5V8a2 2 0 004 0 2 2 0 011.523-1.943A5.977 5.977 0 0116 10c0 .34-.028.675-.083 1H15a2 2 0 00-2 2v2.197A5.973 5.973 0 0110 16v-2a2 2 0 00-2-2 2 2 0 01-2-2 2 2 0 00-1.668-1.973z" clipRule="evenodd" />
        </svg>
        <span>City</span>
      </div>
      <RefinementList
        attribute="contactCity"
        className="text-sm"
        showMore
      />
    </div>
    <div className="heaven-search-filter-section">
      <div className="text-base px-4 pt-4 pb-2 font-semibold flex">
        <svg className="h-5 w-5 opacity-50 text-gray-600 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM4.332 8.027a6.012 6.012 0 011.912-2.706C6.512 5.73 6.974 6 7.5 6A1.5 1.5 0 019 7.5V8a2 2 0 004 0 2 2 0 011.523-1.943A5.977 5.977 0 0116 10c0 .34-.028.675-.083 1H15a2 2 0 00-2 2v2.197A5.973 5.973 0 0110 16v-2a2 2 0 00-2-2 2 2 0 01-2-2 2 2 0 00-1.668-1.973z" clipRule="evenodd" />
        </svg>
        <span>State</span>
      </div>
      <RefinementList
        attribute="contactState"
        className="text-sm"
        showMore
      />
    </div>
    <div className="heaven-search-filter-section">
      <div className="text-base px-4 pt-4 pb-2 font-semibold flex">
        <svg className="h-5 w-5 opacity-50 text-gray-600 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM4.332 8.027a6.012 6.012 0 011.912-2.706C6.512 5.73 6.974 6 7.5 6A1.5 1.5 0 019 7.5V8a2 2 0 004 0 2 2 0 011.523-1.943A5.977 5.977 0 0116 10c0 .34-.028.675-.083 1H15a2 2 0 00-2 2v2.197A5.973 5.973 0 0110 16v-2a2 2 0 00-2-2 2 2 0 01-2-2 2 2 0 00-1.668-1.973z" clipRule="evenodd" />
        </svg>
        <span>Country</span>
      </div>
      <RefinementList
        attribute="contactCountry"
        className="text-sm"
        showMore
      />
    </div>
    <div className="heaven-search-filter-section">
      <div className="text-base px-4 pt-4 pb-2 font-semibold flex">
        <svg className="h-5 w-5 opacity-50 text-gray-600 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
        </svg>
        <span>Location Preferences</span>
      </div>
      <RefinementList
        attribute="geographicPreferences"
        className="text-sm"
        showMore
      />
    </div>
    <div className="heaven-search-filter-section">
      <div className="text-base px-4 pt-4 pb-2 font-semibold flex">
        <svg className="h-5 w-5 opacity-50 text-gray-600 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M17.707 9.293a1 1 0 010 1.414l-7 7a1 1 0 01-1.414 0l-7-7A.997.997 0 012 10V5a3 3 0 013-3h5c.256 0 .512.098.707.293l7 7zM5 6a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
        </svg>
        <span>Industry Preferences</span>
      </div>
      <RefinementList
        attribute="industryPreferences"
        className="text-sm"
        showMore
      />
    </div>
    <div className="heaven-search-filter-section">
      <div className="text-base px-4 pt-4 pb-2 font-semibold flex">
        <svg className="h-5 w-5 opacity-50 text-gray-600 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
        </svg>
        <span>Fund Type</span>
      </div>
      <RefinementList
        attribute="typeOfFirm"
        className="text-sm"
      />
    </div>
    <div className="heaven-search-filter-section">
      <div className="text-base px-4 pt-4 pb-2 font-semibold flex">
        <svg className="h-5 w-5 opacity-50 text-gray-600 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M17.707 9.293a1 1 0 010 1.414l-7 7a1 1 0 01-1.414 0l-7-7A.997.997 0 012 10V5a3 3 0 013-3h5c.256 0 .512.098.707.293l7 7zM5 6a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
        </svg>
        <span>Stage Preferences</span>
      </div>
      <RefinementList
        attribute="stagePreferences"
        className="text-sm"
        showMore
      />
    </div>
    <div className="heaven-search-filter-section">
      <div className="text-base px-4 pt-4 pb-2 font-semibold flex">
        <svg className="h-5 w-5 opacity-50 text-gray-600 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
        </svg>
        <span>Assets Under Management</span>
      </div>
      <RefinementList
        attribute="capManFilter"
        className="text-sm"
      />
    </div>
    <div className="heaven-search-filter-section">
      <div className="text-base px-4 pt-4 pb-2 font-semibold flex">
        <svg className="h-5 w-5 opacity-50 text-gray-600 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
        </svg>
        <span>Minimum Investment</span>
      </div>
      <RefinementList
        attribute="minInvFilter"
        className="text-sm"
      />
    </div>
    <div className="heaven-search-filter-section">
      <div className="text-base px-4 pt-4 pb-2 font-semibold flex">
        <svg className="h-5 w-5 opacity-50 text-gray-600 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
        </svg>
        <span>Maximum Investment</span>
      </div>
      <RefinementList
        attribute="maxInvFilter"
        className="text-sm"
      />
    </div>
    <div className="heaven-search-filter-section">
      <div className="text-base px-4 pt-4 pb-2 font-semibold flex">
        <svg className="h-5 w-5 opacity-50 text-gray-600 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z" clipRule="evenodd" />
        </svg>
        <span>Firm</span>
      </div>
      <RefinementList
        attribute="contactFirmName"
        className="text-sm"
        showMore
      />
    </div>
    <div className="heaven-search-filter-section">
      <div className="text-base px-4 pt-4 pb-2 font-semibold flex">
        <svg className="h-5 w-5 opacity-50 text-gray-600 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z" clipRule="evenodd" />
          <path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z" />
        </svg>
        <span>Job title</span>
      </div>
      <RefinementList
        attribute="contactTitle"
        className="text-sm"
        showMore
      />
    </div>
    <div className="heaven-search-filter-section">
      <div className="text-base px-4 pt-4 pb-2 font-semibold flex">
        <svg className="h-5 w-5 opacity-50 text-gray-600 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
        </svg>
        <span>Number of investors</span>
      </div>
      <RefinementList
        attribute="investorRange"
        className="text-sm"
      />
    </div>
    <div className="heaven-search-filter-section" style={{ borderBottom:'none' }}>
      <div className="text-base px-4 pt-4 pb-2 font-semibold flex">
        <svg className="h-5 w-5 opacity-50 text-gray-600 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M6.625 2.655A9 9 0 0119 11a1 1 0 11-2 0 7 7 0 00-9.625-6.492 1 1 0 11-.75-1.853zM4.662 4.959A1 1 0 014.75 6.37 6.97 6.97 0 003 11a1 1 0 11-2 0 8.97 8.97 0 012.25-5.953 1 1 0 011.412-.088z" clipRule="evenodd" />
          <path fillRule="evenodd" d="M5 11a5 5 0 1110 0 1 1 0 11-2 0 3 3 0 10-6 0c0 1.677-.345 3.276-.968 4.729a1 1 0 11-1.838-.789A9.964 9.964 0 005 11zm8.921 2.012a1 1 0 01.831 1.145 19.86 19.86 0 01-.545 2.436 1 1 0 11-1.92-.558c.207-.713.371-1.445.49-2.192a1 1 0 011.144-.83z" clipRule="evenodd" />
          <path fillRule="evenodd" d="M10 10a1 1 0 011 1c0 2.236-.46 4.368-1.29 6.304a1 1 0 01-1.838-.789A13.952 13.952 0 009 11a1 1 0 011-1z" clipRule="evenodd" />
        </svg>
        <span>Gender</span>
      </div>
      <RefinementList
        attribute="gender"
        className="text-sm"
      />
    </div>
  </div>
);

export default InvestorFilters;

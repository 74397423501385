import React from 'react';
import { NavLink } from 'react-router-dom';

const EmptyListPlaceholder = () => (
	<div className="bg-white shadow overflow-hidden sm:rounded-md">
	  <ul>
	    <li className="border-t border-gray-200">
	      <NavLink to="/pricing" className="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out">
	        <div className="flex items-center px-4 py-4 sm:px-6">
	          <div className="min-w-0 flex-1 flex items-center">
							<svg className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
							</svg>
	            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
	              <div>
	                <div className="text-sm leading-5 font-medium text-indigo-600 truncate">SaaS Investors</div>
	                <div className="mt-2 flex items-center text-sm leading-5 text-gray-500">
	                  <span className="truncate">This list contains all the investors who invest in the SaaS space</span>
	                </div>
	              </div>
	              <div className="hidden md:block">
	                <div>
	                  <div className="text-sm leading-5 text-gray-900">
	                  	<span>556 contacts</span>
	                  </div>
	                  <div className="mt-2 flex items-center text-sm leading-5 text-gray-500">
	                    <span>Last updated&nbsp;3 hours ago</span>
	                  </div>
	                </div>
	              </div>
	            </div>
	          </div>
	          <div>
	            <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
	              <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
	            </svg>
	          </div>
	        </div>
	      </NavLink>
	    </li>
	    <li className="border-t border-gray-200">
	      <NavLink to="/pricing" className="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out">
	        <div className="flex items-center px-4 py-4 sm:px-6">
	          <div className="min-w-0 flex-1 flex items-center">
							<svg className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
							</svg>
	            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
	              <div>
	                <div className="text-sm leading-5 font-medium text-indigo-600 truncate">Consumer Tech Investors</div>
	                <div className="mt-2 flex items-center text-sm leading-5 text-gray-500">
	                  <span className="truncate">This list contains all the investors who invest in the consumer tech space</span>
	                </div>
	              </div>
	              <div className="hidden md:block">
	                <div>
	                  <div className="text-sm leading-5 text-gray-900">
	                  	<span>327 contacts</span>
	                  </div>
	                  <div className="mt-2 flex items-center text-sm leading-5 text-gray-500">
	                    <span>Last updated&nbsp;14 days ago</span>
	                  </div>
	                </div>
	              </div>
	            </div>
	          </div>
	          <div>
	            <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
	              <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
	            </svg>
	          </div>
	        </div>
	      </NavLink>
	    </li>
	    <li className="border-t border-gray-200">
	      <NavLink to="/pricing" className="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out">
	        <div className="flex items-center px-4 py-4 sm:px-6">
	          <div className="min-w-0 flex-1 flex items-center">
							<svg className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
							</svg>
	            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
	              <div>
	                <div className="text-sm leading-5 font-medium text-indigo-600 truncate">NYC-based Investors</div>
	                <div className="mt-2 flex items-center text-sm leading-5 text-gray-500">
	                  <span className="truncate">This list contains all the investors who are focusing on the greater NY area</span>
	                </div>
	              </div>
	              <div className="hidden md:block">
	                <div>
	                  <div className="text-sm leading-5 text-gray-900">
	                  	<span>716 contacts</span>
	                  </div>
	                  <div className="mt-2 flex items-center text-sm leading-5 text-gray-500">
	                    <span>Last updated&nbsp;32 days ago</span>
	                  </div>
	                </div>
	              </div>
	            </div>
	          </div>
	          <div>
	            <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
	              <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
	            </svg>
	          </div>
	        </div>
	      </NavLink>
	    </li>
	  </ul>
	</div>
);

export default EmptyListPlaceholder;

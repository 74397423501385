import React, { useState } from 'react';
import { connectHitsPerPage } from 'react-instantsearch-dom';

import Transition from '../../styles/Transition.js';

function HitsPerPage ({ items, currentRefinement, refine, createURL }) {

  const [hitsDropdownOn, setHitsDropdown] = useState(false);

  return (
    <div className="relative">
      <button type="button" onClick={() => setHitsDropdown(!hitsDropdownOn)}  className="text-base bg-white hover:text-gray-900 text-gray-700 font-semibold py-2 px-4 border border-gray-200 rounded-lg shadow-sm transition ease-in-out duration-150 inline-flex items-center">
        <svg className="opacity-50 fill-current outline-current mr-2 h-5 w-5 inline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
          <path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd" />
        </svg>
        {currentRefinement} contacts
        <svg className="opacity-50 ml-2 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
        </svg>
      </button>

      <Transition
        show={hitsDropdownOn}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div className={`${hitsDropdownOn ? 'block' : 'hidden'} origin-top-right z-10 absolute right-0 mt-2 w-56 rounded-lg shadow-sm`}>
          <div className="rounded-md bg-white shadow-xs" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            {items.map(item => (
              <div key={item.value}>
                <a
                  href={createURL(item.value)}
                  className="block cursor-pointer px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                  style={{ color: item.isRefined ? '#003dff' : '' }}
                  onClick={event => {
                    event.preventDefault();
                    setHitsDropdown(!hitsDropdownOn);
                    refine(item.value);
                  }}
                >
                  <i className="fas fa-user-friends heaven-page-dropdown-item-icon" />
                  {item.label}
                </a>
              </div>
            ))}
          </div>
        </div>
      </Transition>
    </div>
  )
};

const CustomHitsPerPage = connectHitsPerPage(HitsPerPage);
export default CustomHitsPerPage;

import Firebase from 'firebase/app';

const subscribeUser = (authUser, plan) => {
  return Firebase.firestore()
    .collection(`subscriptions`)
    .add({
			createdBy: authUser.uid,
			createdOn: Firebase.firestore.Timestamp.now(),
			user: authUser.uid,
      email: authUser.email,
      userEmail: authUser.email,
      plan, 
      stripeSubscriptionStatus: "active",
      stripeSubscriptionId: "appsumo",
      updatedOn: Firebase.firestore.Timestamp.now(),
    })
    .catch( error => {
      alert(`Whoops, couldn't create user: ${error.message}`)
    })
}

export default subscribeUser;

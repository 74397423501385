import React from 'react';

const TermsAndConditions = () => (
	<div className="relative py-16 bg-white overflow-hidden">
	  <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
	    <div className="relative h-full text-lg max-w-prose mx-auto">
	      <svg className="absolute top-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
	        <defs>
	          <pattern id="74b3fd99-0a6f-4271-bef2-e80eeafdf357" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
	            <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
	          </pattern>
	        </defs>
	        <rect width="404" height="384" fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
	      </svg>
	      <svg className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
	        <defs>
	          <pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
	            <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
	          </pattern>
	        </defs>
	        <rect width="404" height="384" fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
	      </svg>
	      <svg className="absolute bottom-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
	        <defs>
	          <pattern id="d3eb07ae-5182-43e6-857d-35c643af9034" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
	            <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
	          </pattern>
	        </defs>
	        <rect width="404" height="384" fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
	      </svg>
	    </div>
	  </div>
	  <div className="relative px-4 sm:px-6 lg:px-8">
	    <div className="text-lg max-w-prose mx-auto mb-6">
	      <p className="text-base text-center leading-6 text-indigo-600 font-semibold tracking-wide uppercase">Investor Scout</p>
	      <h1 className="mt-2 mb-8 text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">Terms and Conditions</h1>
	    </div>
	    <div className="prose prose-lg text-gray-600 mx-auto">
	      <p className="text-md text-gray-700 leading-8">These Terms govern</p>
				<ul>
					<li>the use of this Application, and,</li>
					<li>any other related Agreement or legal relationship with the Owner</li>
				</ul>
				<p>in a legally binding way. Capitalized words are defined in the relevant dedicated section of this document.</p>
				<br />
				<p>The User must read this document carefully.</p>
				<br /><hr /><br />
				<p>This Application is provided by:</p>
				<p>West Vesey LLC.</p>
				<p><b>Owner contact email:</b> matt@westvesey.com</p>
				<br />
				<h2 className="text-xl text-gray-600 leading-8" id="what-the-user-should-know-at-a-glance">What the User should know at a glance</h2>
				<br /><hr /><br />

				<h2 className="text-xl text-gray-800 leading-8 my-5" id="terms-of-use">TERMS OF USE</h2><p>Unless otherwise specified, the terms of use detailed in this section apply generally when using this Application.</p>

				<p>Single or additional conditions of use or access may apply in specific scenarios and in such cases are additionally indicated within this document.</p>

				<p>By using this Application, Users confirm to meet the following requirements:</p>
				<h3 className="text-lg text-gray-700 leading-8 mt-5 mb-3" id="content-on-this_application">Content on this Application</h3>
				<p>Unless where otherwise specified or clearly recognizable, all content available on this Application is owned or provided by the Owner or its licensors.</p>

				<p>The Owner undertakes its utmost effort to ensure that the content provided on this Application infringes no applicable legal provisions or third-party rights. However, it may not always be possible to achieve such a result.<br />
				In such cases, without prejudice to any legal prerogatives of Users to enforce their rights, Users are kindly asked to preferably report related complaints using the contact details provided in this document.</p>
				<h3 className="text-lg text-gray-700 leading-8 mt-5 mb-3" id="access-to-external-resources">Access to external resources</h3>
				<p>Through this Application Users may have access to external resources provided by third parties. Users acknowledge and accept that the Owner has no control over such resources and is therefore not responsible for their content and availability.</p>

				<p>Conditions applicable to any resources provided by third parties, including those applicable to any possible grant of rights in content, result from each such third parties’ terms and conditions or, in the absence of those, applicable statutory law.</p>
				<h3 className="text-lg text-gray-700 leading-8 mt-5 mb-3" id="acceptable-use">Acceptable use</h3>
				<p>This Application and the Service may only be used within the scope of what they are provided for, under these Terms and applicable law.</p>

				<p>Users are solely responsible for making sure that their use of this Application and/or the Service violates no applicable law, regulations or third-party rights.</p>
				<h2 className="text-xl text-gray-800 leading-8 my-5" id="common-provisions">Common provisions</h2><h3 id="no-waiver">No Waiver</h3>
				<p>The Owner’s failure to assert any right or provision under these Terms shall not constitute a waiver of any such right or provision.  No waiver shall be considered a further or continuing waiver of such term or any other term.</p>
				<h3 className="text-lg text-gray-700 leading-8 mt-5 mb-3" id="service-interruption">Service interruption</h3>
				<p>To ensure the best possible service level, the Owner reserves the right to interrupt the Service for maintenance, system updates or any other changes, informing the Users appropriately.</p>

				<p>Within the limits of law, the Owner may also decide to suspend or terminate the Service altogether. If the Service is terminated, the Owner will cooperate with Users to enable them to withdraw Personal Data or information in accordance with applicable law.</p>

				<p>Additionally, the Service might not be available due to reasons outside the Owner’s reasonable control, such as “force majeure” (eg. labor actions, infrastructural breakdowns or blackouts etc).</p>
				<h3 className="text-lg text-gray-700 leading-8 mt-5 mb-3" id="service-reselling">Service reselling</h3>
				<p>Users may not reproduce, duplicate, copy, sell, resell or exploit any portion of this Application and of its Service without the Owner’s express prior written permission, granted either directly or through a legitimate reselling program.</p>
				<h3 className="text-lg text-gray-700 leading-8 mt-5 mb-3" id="intellectual-property-rights">Intellectual property rights</h3>
				<p>Without prejudice to any more specific provision of these Terms, any intellectual property rights, such as copyrights, trademark rights, patent rights and design rights related to this Application are the exclusive property of the Owner or its licensors and are subject to the protection granted by applicable laws or international treaties relating to intellectual property.</p>

				<p>All trademarks — nominal or figurative — and all other marks, trade names, service marks, word marks, illustrations, images, or logos appearing in connection with this Application are, and remain, the exclusive property of the Owner or its licensors and are subject to the protection granted by applicable laws or international treaties related to intellectual property.</p>
				<h3 id="changes-to-these-terms">Changes to these Terms</h3>
				<p>The Owner reserves the right to amend or otherwise modify these Terms at any time. In such cases, the Owner will appropriately inform the User of these changes.</p>

				<p>Such changes will only affect the relationship with the User for the future.</p>

				<p>The continued use of the Service will signify the User’s acceptance of the revised Terms. If Users do not wish to be bound by the changes, they must stop using the Service. Failure to accept the revised Terms, may entitle either party to terminate the Agreement.</p>

				<p>The applicable previous version will govern the relationship prior to the User's acceptance. The User can obtain any previous version from the Owner.</p>
				<h3 className="text-lg text-gray-700 leading-8 mt-5 mb-3" id="assignment-of-contract">Assignment of contract</h3>
				<p>The Owner reserves the right to transfer, assign, dispose of by novation, or subcontract any or all rights or obligations under these Terms, taking the User’s legitimate interests into account.
				Provisions regarding changes of these Terms will apply accordingly.</p>

				<p>Users may not assign or transfer their rights or obligations under these Terms in any way, without the written permission of the Owner.</p>
				<h3 className="text-lg text-gray-700 leading-8 mt-5 mb-3" id="contacts">Contacts</h3>
				<p>All communications relating to the use of this Application must be sent using the contact information stated in this document.</p>
				<h3 className="text-lg text-gray-700 leading-8 mt-5 mb-3" id="severability">Severability</h3>
				<p>Should any provision of these Terms be deemed or become invalid or unenforceable under applicable law, the invalidity or unenforceability of such provision shall not affect the validity of the remaining provisions, which shall remain in full force and effect.</p>
				<h2 className="text-xl text-gray-800 leading-8 my-5" id="guarantees">Guarantees</h2>
				<h3 className="text-lg text-gray-700 leading-8 mt-5 mb-3" id="money-back-guarantee-for-goods">Money-back-guarantee for goods</h3>
				<p>Without prejudice to any applicable statutory rights, the Owner grants Users the right to cancel a purchase they are unsatisfied with, and obtain a refund, within 0 days from the day on which the purchased goods have been delivered.</p>

				<p>The Owner shall refund the price of the Product purchased using the same payment method as the original transaction.<br />
				In order to make use of this right, Users must send an unambiguous notice to the Owner. While a motivation is not required, Users are kindly asked to specify the reason why they are claiming the money-back-guarantee.</p>

				<p>Within the same period of time stated above, Users must also return the purchased goods to the Owner at their own expense, making sure that they are undamaged, clean and fit for resale. Goods must be returned inside the original packaging.<br />
				Upon receipt of the goods, the Owner will verify that all conditions applicable to the money-back-guarantee are fulfilled and, if so, refund the purchase price.</p>
				<h3 className="text-lg text-gray-700 leading-8 mt-5 mb-3" id="money-back-guarantee-for-services">Money-back-guarantee for services</h3>
				<p>Without prejudice to any applicable statutory rights, the Owner grants Users the right to cancel the purchase of a service they are unsatisfied with, and obtain a refund, within 0 days from the day on which the contract was entered into.</p>

				<p>The Owner shall refund the price of the Product purchased using the same payment method as the original transaction.<br />
				In order to make use of this right, Users must send an unambiguous notice to the Owner. While a motivation is not required, Users are kindly asked to specify the reason why they are claiming the money-back-guarantee.</p>

				<p>Upon receipt of such notice, the Owner will verify that all conditions applicable to the money-back-guarantee are fulfilled and, if so, refund the purchase price. In this case, Users will no longer have access to the purchased service.</p>
  
	    </div>
	    <br /><hr /><br />
      <p>Latest update: January 15, 2021</p>

	  </div>
	</div>
);

export default TermsAndConditions;

import React from 'react';
import Firebase from 'firebase/app';
import { NavLink } from 'react-router-dom';

import deleteEntry from '../../actions/deleteEntry';
import clearList from '../../actions/clearList';
import Loading from '../misc/Loading';
import EmptyList from '../misc/emptyList';
import ListHookForm from '../Dashboard/listHookForm';
import CSV from './csv';

import Transition from '../../styles/Transition.js';

class InfiniteList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    	dbItems: [],
    	itemsPerPage: 50,
      pageNumber: 0,
    	totalItemCount: 0,
      listEntries:[],
    	searchTerm: '',
      list: {},
      isLoading: true,
      modalOn: false,
      warningModalOn: false,
      plan: this.props.plan,
    };
    this.getDocument = this.getDocument.bind(this);
    this.activateModal = this.activateModal.bind(this);
    this.activateWarningModal = this.activateWarningModal.bind(this);
    this.reDirect = this.reDirect.bind(this);
  }

  componentDidMount() {
    this.getDocument();
	}

  activateModal() {
    this.setState(prevState => ({
      modalOn: !prevState.modalOn,
    }));
  }

  activateWarningModal() {
    this.setState(prevState => ({
      warningModalOn: !prevState.warningModalOn,
    }));
  }

  reDirect() {
    this.props.history.push("/lists");
  }

  getDocument = () => {
    var db = Firebase.firestore();
    var listData = db.collection('lists')
      .where('createdBy', '==', this.props.subscription.user).where('slug', '==', this.props.match.params.slug)
      .get()
      .then(documentSnapshots => {
        documentSnapshots.forEach(doc => {
          this.setState({
            list: doc.data(),
            listId: doc.id,
            listEntries: db.collection('lists').doc(doc.id).collection('entries')
          })
        })
      })
      .then( () => this.getFirstItems())
  };

  getFirstItems = () => {
    var listSize = this.state.listEntries
      .get()
      .then(snap => {
        this.setState({
          totalItemCount: snap.size,
          listEntriesAll: snap.docs.map(doc => doc)
        })
      })
    var listEntries = this.state.listEntries
      .orderBy("nameToOrderBy")
      .startAt(0)
      .limit(this.state.itemsPerPage)
      .get()
      .then( documentSnapshots => {
        var pageNumber = 1;
      	var firstVisible = documentSnapshots.docs[0];
      	var lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];
        var items = documentSnapshots.docs.map(doc => doc);
        return this.setItems(items, firstVisible, lastVisible, pageNumber)
      })
  }

  showNextPage = () => {
    var listEntries = this.state.listEntries
      .orderBy("nameToOrderBy")
      .startAfter(this.state.lastVisible)
      .limit(this.state.itemsPerPage)
      .get()
      .then( documentSnapshots => {
        var pageNumber = this.state.pageNumber += 1;
      	var firstVisible = documentSnapshots.docs[0]
      	var lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];
        var items = documentSnapshots.docs.map(doc => doc)
        return this.setItems(items, firstVisible, lastVisible, pageNumber)
      })
  };

  showPreviousPage = () => {
    var listEntries = this.state.listEntries
      .orderBy("nameToOrderBy")
      .endBefore(this.state.firstVisible)
      .limitToLast(this.state.itemsPerPage)
      .get()
      .then( documentSnapshots => {
        var pageNumber = this.state.pageNumber -= 1;
      	var firstVisible = documentSnapshots.docs[0]
      	var lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];
        var items = documentSnapshots.docs.map(doc => doc)
        return this.setItems(items, firstVisible, lastVisible, pageNumber)
      })
  };

  findSearchResult = () => {
    var searchTerm = this.state.searchTerm;
    console.log(searchTerm)
    var listEntries = this.state.listEntries
    	.where("nameToOrderBy", "==", searchTerm)
      .get()
      .then( documentSnapshots => {
      	var firstVisible = documentSnapshots.docs[0]
      	var lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];
        var items = documentSnapshots.docs.map(doc => doc)
        console.log(firstVisible)
        return this.setItems(items, firstVisible, lastVisible)
      })
  }

  setItems = (items, firstVisible, lastVisible, pageNumber) => {
    this.setState({
      isLoading: false,
      dbItems: items,
      firstVisible,
      lastVisible,
      pageNumber,
    })
  }

  handleChange = event => {
    this.setState({
    	searchTerm: event.target.value,
    })
  };

  handleEnter = async (event) => {
    if (event.keyCode === 13) {
      if (this.state.searchTerm === "" || this.state.searchTerm === " ") {
      	this.getFirstItems()
      } else {
        this.findSearchResult()
      }
    }
  };

  FundTitle  = entry => {
    return (
      <div className="flex items-center">
        <div className="ml-4">
          <div className="text-sm align-middle text-left leading-5 font-medium text-gray-900">
            {entry.data().officeFirmName}
          </div>
          <div className="text-sm align-middle text-left leading-5 text-gray-500">
            {entry.data().typeOfFirm} in {entry.data().officeCity} {entry.data().officeState} {entry.data().officeCountry}
          </div>
        </div>
      </div>
    )
  }

  InvestorTitle  = entry => {
    return (
      <div className="flex items-center">
        <div className="ml-4">
          <div className="text-sm align-middle text-left leading-5 font-medium text-gray-900">
            {entry.data().contactFirstName} {entry.data().contactLastName}
          </div>
          <div className="text-sm align-middle text-left leading-5 text-gray-500">
            {entry.data().contactTitle} at {entry.data().contactFirmName}
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { list, listId, isLoading, totalItemCount, warningModalOn, modalOn, listEntriesAll, dbItems, plan } = this.state;
    if (isLoading) {
      return <Loading />
    } else {
      return (
        <div>
          <div className={`${warningModalOn ? 'block z-10' : 'hidden'} fixed inset-0 overflow-y-auto`}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition
                show={warningModalOn}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 transition-opacity">
                  <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
              </Transition>

              <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
              <Transition
                show={warningModalOn}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <svg className="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                        </svg>
                      </div>
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                          Clear list
                        </h3>
                        <div className="mt-2">
                          <p className="text-sm leading-5 text-gray-500">
                            Are you sure you want to clear this list? All of your data will be permanently removed. This action cannot be undone.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-100 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                      <button
                        type="button"
                        onClick={ async () => {
                          await this.setState({ isLoading: true, warningModalOn: false, });
                          await clearList(listId);
                          await this.getFirstItems();
                        }}
                        className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-red-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                        Clear list
                      </button>
                    </span>
                    <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                      <button
                        type="button"
                        onClick={this.activateWarningModal}
                        className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                      >
                        Cancel
                      </button>
                    </span>
                  </div>
                </div>
              </Transition>
            </div>
          </div>
          <div className={`${modalOn ? 'block z-10' : 'hidden'} fixed inset-0 overflow-y-auto`}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition
                show={modalOn}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 transition-opacity">
                  <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
              </Transition>

              <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
              <Transition
                show={modalOn}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <ListHookForm
                  reDirect={this.reDirect}
                  activateModal={this.activateModal}
                  getDocument={this.getDocument}
                  fromDash={false}
                  listId={listId}
                  list={list}
                  isUpdate={true}
                />
              </Transition>
            </div>
          </div>
          <div className="max-w-7xl py-10 mx-auto px-4 lg:px-40">
            <div className="mb-5 space-y-3 sm:flex sm:items-center sm:justify-between sm:space-x-4 sm:space-y-0">
              <div>
                <div className="flex">
                  <h3 className="text-lg leading-6 font-bold text-gray-900 mr-2">{list.name}</h3>
                  <button onClick={this.activateModal}>
                    <svg className="h-5 w-5 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                    </svg>
                  </button>
                </div>
                <p className="py-4 text-sm font-small whitespace-no-wrap text-gray-500">{list.description}</p>
              </div>
              <div>
                <button
                  onClick={this.activateWarningModal}
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-base leading-5 font-medium rounded-lg shadow-sm text-white bg-red-1000 focus:outline-none hover:opacity-75 transition duration-150 ease-in-out"
                >
                  <svg className="opacity-50 fill-current outline-current h-5 w-5 inline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                  </svg>
                  <span className="ml-2">Clear list</span>
                </button>
                <span>
                  <CSV entries={listEntriesAll} plan={plan} />
                </span>
              </div>
            </div>
            { totalItemCount > 0 ? (
            <div className="container mx-auto mb-24">
              <div>
                <div className="mb-12">
                  <div className="relative flex-grow focus-within:z-10">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M9 9a2 2 0 114 0 2 2 0 01-4 0z" />
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a4 4 0 00-3.446 6.032l-2.261 2.26a1 1 0 101.414 1.415l2.261-2.261A4 4 0 1011 5z" clipRule="evenodd" />
                      </svg>
                    </div>
                    <input
                      type="text"
                      placeholder="Search by exact name of firm…"
                      className="form-input block w-full rounded-none rounded-lg shadow-sm border border-gray-200 pl-10 transition ease-in-out duration-150 leading-10"
                      value={this.state.searchTerm}
                      onChange={this.handleChange}
                      onKeyDown={this.handleEnter}
                    />
                  </div>
                  {/*<button className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-r-md text-gray-700 bg-gray-50 hover:text-gray-500 hover:bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
                    <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                    </svg>
                    <span className="ml-2">Sort</span>
                  </button>*/}
                </div>
              </div>
              <div className="flex flex-col">
                <div className="-my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                  <div className="border border-gray-200 align-middle inline-block min-w-full shadow-sm overflow-hidden rounded-lg border-b border-gray-200">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead>
                        <tr>
                          <th className="px-4 py-3 border-b border-gray-00 bg-gray-200"></th>
                          <th className="px-6 py-3 border-b border-gray-00 bg-gray-200 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Name
                          </th>
                          <th className="px-6 py-3 border-b border-gray-00 bg-gray-200 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Type
                          </th>
                          <th className="px-6 py-3 border-b border-gray-00 bg-gray-200 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Contact
                          </th>
                          {/*<th className="px-6 py-3 border-b border-gray-00 bg-gray-200 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Add notes
                          </th>*/}
                          <th className="px-6 py-3 border-b border-gray-00 bg-gray-200"></th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                      {dbItems.length > 0 ? (
                        dbItems.map((entry, index) => (
                        <tr key={index}>
                          <td className="px-4 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
                            <button onClick={() => deleteEntry(listId, entry.id, entry.data().dataType, entry.data().objectID).then(() => this.getFirstItems())}>
                              <svg className="h-5 w-5 text-red-1000 hover:text-red-400 transition ease-in-out duration-150" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                              </svg>
                            </button>
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                            {entry.data().dataType === "Fund" ? this.FundTitle(entry) :  this.InvestorTitle(entry)}
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                            <span className={`${entry.data().dataType === "Fund" ? 'bg-green-200 text-green-500' : 'bg-yellow-200 text-yellow-500'} px-2 inline-flex text-xs leading-5 font-semibold rounded-full`}>
                              {entry.data().dataType ? entry.data().dataType.toUpperCase() : ""}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                            {entry.data().dataType === "Fund" ? entry.data().officeEmail : entry.data().contactWorkEmail}
                          </td>
                          {/*<td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                            <svg className="h-5 w-5 mx-auto text-gray-400" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                              <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                            </svg>
                          </td>*/}
                          <td className="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
                            <NavLink to={`/list/${list.slug}/${entry.data().objectID}`}>
                              <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                              </svg>
                            </NavLink>
                          </td>
                        </tr>
                        ))
                      ) : <tr /> }
                      </tbody>
                    </table>
                    <nav className="bg-white px-4 py-3 flex items-center justify-between sm:px-6">
                      <div className="hidden sm:block">
                        <p className="text-sm leading-5 text-gray-700">
                          Showing
                          <span className="font-medium"> {(this.state.pageNumber-1)*this.state.itemsPerPage + 1} </span>
                          to
                          <span className="font-medium"> {this.state.pageNumber*this.state.itemsPerPage < this.state.totalItemCount ? this.state.pageNumber*this.state.itemsPerPage : this.state.totalItemCount } </span>
                          of
                          <span className="font-medium"> {this.state.totalItemCount} </span>
                          results
                        </p>
                      </div>
                      <div className="flex-1 flex justify-between sm:justify-end">
                      {this.state.pageNumber > 1 ? (
                        <button onClick={this.showPreviousPage} className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
                          Previous
                        </button>
                      ) : <span /> }
                      {this.state.pageNumber < this.state.totalItemCount/this.state.itemsPerPage ? (
                        <button onClick={this.showNextPage} className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
                          Next
                        </button>
                      ) : <span /> }
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div> ) : <EmptyList /> }
          </div>
        </div>
      );
    }
  }
}

export default InfiniteList;

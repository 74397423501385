import React from 'react';
import Error from './Error';
import FireflySubscription from './FireflySubscription';

const SubscriptionPlan = () => (
  <FireflySubscription>
    { ({isLoading, error, subscription}) => {

      if (error) {
        return <Error error={error} />
      }
      if (isLoading) {
        return <p>loading...</p>
      }
      if (!subscription) {
        return <p>No active subscription</p>
      }
      if (subscription.upgrade) {
        return <p>Upgrading...</p>
      }
      if (subscription.stripeSubscriptionStatus !== "active") {
        return <p>No active subscription</p>        
      }
      if (subscription.plan === 4 || subscription.plan === 40) {
        return <p>You are currently on the Pro Plan.</p>
      }
      if (subscription.plan === 3 || subscription.plan === 30) {
      	return <p>You are currently on the Premium Plan.</p>
      }
      if (subscription.plan === 2 || subscription.plan === 20) {
      	return <p>You are currently on the Startup Plan.</p>
      }
      if (subscription.plan === 1 || subscription.plan === 10) {
      	return <p> You are currently on the Basic Plan.</p>
      }
      else {
      	return <div />
      }
   }}
  </FireflySubscription>
)

export default SubscriptionPlan

import React from 'react';
import { NavLink } from 'react-router-dom';

import Melanie from '../../assets/melanie-elturk.jpg';
import Tim from '../../assets/tim-1440.jpeg';
import Michael from '../../assets/michael-romrell.jpeg';
import Haute from '../../assets/hautehijabwhite.svg';
import Fourteen40 from '../../assets/1440.svg';
// import Literal from '../../assets/literal.png';

const Testimonials = () => (
  <div>
    <section className="py-12 bg-gray-100 overflow-hidden md:py-20 lg:py-24" id="testimonials">
      <div className="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <svg className="absolute top-full right-full transform translate-x-1/3 -translate-y-1/4 lg:translate-x-1/2 xl:-translate-y-1/2" width="404" height="404" fill="none" viewBox="0 0 404 404" role="img" aria-labelledby="svg-workcation">
          <title id="svg-workcation">Literal</title>
          <defs>
            <pattern id="ad119f34-7694-4c31-947f-5c9d249b21f3" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="404" fill="url(#ad119f34-7694-4c31-947f-5c9d249b21f3)" />
        </svg>

        <div className="relative">
          <blockquote className="mt-8">
            <div className="max-w-3xl mx-auto text-center text-2xl leading-9 font-medium text-gray-900">
              <p>
                &ldquo;We were struggling to get investor traction for two months. But <span className="font-bold text-investor-green">as soon as I started using Investor Scout, we raised over $1m for our seed round in 30 days</span>.<br /><br /><span className="font-bold text-investor-green">We completely oversubscribed the round and ended up turning down a billionaire</span>, several tech icons and some big-name funds in order to make room for the investors that were right for us.&rdquo;
              </p>
            </div>
            <footer className="mt-8">
              <div className="md:flex md:items-center md:justify-center">
                <div className="md:flex-shrink-0">
                  <img className="mx-auto h-10 w-10 rounded-full" src={Michael} alt="Michael Romrell from Literal" />
                </div>
                <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                  <div className="text-base leading-6 font-medium text-gray-900">Michael Romrell</div>
                  <svg className="hidden md:block mx-1 h-5 w-5 text-investor-blue" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M11 0h3L9 20H6l5-20z" />
                  </svg>
                  <div className="text-base leading-6 font-medium text-gray-500">Co-founder & President, Literal</div>
                </div>
              </div>
            </footer>
          </blockquote>
        </div>
      </div>
    </section>
    <section className="bg-gray-900">
      <div className="max-w-screen-xl mx-auto md:grid md:grid-cols-2 md:px-6 lg:px-8">
        <div className="py-12 px-4 sm:px-6 md:flex md:flex-col md:py-16 md:pl-0 md:pr-10 md:border-r md:border-gray-800 lg:pr-16">
          <div className="md:flex-shrink-0">
            <img className="h-6 sm:h-6 opacity-75" src={Haute} alt="Wharton University of Pennsylvania" />
          </div>
          <blockquote className="mt-8 md:flex-grow md:flex md:flex-col">
            <div className="relative text-lg leading-7 font-medium text-white md:flex-grow">
              <svg className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-gray-800" fill="currentColor" viewBox="0 0 32 32">
                <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
              </svg>
              <p className="relative">
                Best $299 ever spent. As a result of investors we found, we found key investors in our very niche industry that understood our market and eventually wrote checks that allowed us to close our seed round. Definitely coming back when we're ready to raise our Series A.
              </p>
            </div>
            <footer className="mt-8">
              <div className="flex">
                <div className="flex-shrink-0 self-start inline-flex rounded-full border-2 border-white">
                  <img className="h-12 w-12 rounded-full" src={Melanie} alt="Melanie Elturk of Haute Hijab" />
                </div>
                <div className="ml-4">
                  <div className="text-base leading-6 font-medium text-white">Melanie Elturk –– Founder & CEO, Haute Hijab</div>
                  <div className="text-base leading-6 font-medium text-investor-green">Raised $2.3M to date from investors like Sinai & AngelList</div>
                </div>
              </div>
            </footer>
          </blockquote>
        </div>
        <div className="py-12 px-4 border-t-2 border-gray-800 sm:px-6 md:py-16 md:pr-0 md:pl-10 md:border-t-0 md:border-l lg:pl-16">
          <div className="md:flex-shrink-0">
            <img className="h-6 sm:h-6 opacity-75" src={Fourteen40} alt="1440 Media" />
          </div>
          <blockquote className="mt-8 md:flex-grow md:flex md:flex-col">
            <div className="relative text-lg leading-7 font-medium text-white md:flex-grow">
              <svg className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-gray-800" fill="currentColor" viewBox="0 0 32 32">
                <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
              </svg>
              <p className="relative">
                Excellent service - highly recommend for any entrepreneurs raising early stage capital! Incredible value - delivered 100+ relevant investor emails in minutes. Can't think of any cons, excellent service and value!
              </p>
            </div>
            <footer className="mt-8">
              <div className="flex">
                <div className="flex-shrink-0 self-start inline-flex rounded-full border-2 border-white">
                  <img className="h-12 w-12 rounded-full" src={Tim} alt="Tim Huelskamp of 1440 Media" />
                </div>
                <div className="ml-4">
                  <div className="text-base leading-6 font-medium text-white">Tim Huelskamp</div>
                  <div className="text-base leading-6 font-medium text-white text-opacity-50">Founder & CEO, 1440 Media</div>
                </div>
              </div>
            </footer>
          </blockquote>
        </div>
      </div>
      <div className="bg-white border-b border-gray-200">
        <div className="max-w-screen-xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            Ready to raise your round?
            <br />
            Get started for free today.
          </h2>
          <div className="mt-8 flex justify-center">
            <div className="inline-flex rounded-lg shadow-sm">
              <NavLink to="/signup" className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-bold rounded-lg text-white bg-investor-blue hover:opacity-75 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                Get started for free
                <svg className="fill-current opacity-50 w-5 h-5 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z" clipRule="evenodd" />
                </svg>
              </NavLink>
            </div>
            <div className="ml-3 inline-flex rounded-lg">
              <NavLink to="/pricing" className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-bold rounded-lg shadow-sm border border-gray-200 text-gray-900 bg-white focus:outline-none focus:shadow-outline focus:border-indigo-300 transition duration-150 ease-in-out">
                See pricing
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
)

export default Testimonials;

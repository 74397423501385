import React from 'react';
import { NavLink } from 'react-router-dom';

const Cta = () => (
  <div className="bg-indigo-700">
    <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-10 sm:px-4 lg:px-5">
      <h2 className="text-3xl leading-9 font-extrabold text-white sm:text-4xl sm:leading-10">
        <span className="block">Find investors with Investor Scout's database of 52,000 investors</span>
      </h2>
      <p className="mt-4 text-lg leading-6 text-indigo-200">Emails, phone numbers, and search filters are redacted until you subscribe to a plan. After subscribing to a paid plan, you can also create media lists of your favorite investors and export their contact information to CSV! Investor Scout plans start at just $69 and you can upgrade or cancel at any time.</p>
      <NavLink to="/pricing" className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-indigo-600 bg-white hover:text-indigo-500 hover:bg-indigo-50 transition duration-150 ease-in-out sm:w-auto">
        Sign up now
      </NavLink>
    </div>
  </div>
);

export default Cta;

import React from 'react';
import { NavLink } from 'react-router-dom';

export default class HomeDashboard extends React.Component {

	handleCrispClick () {
    this.setState({
      chatBoxOpen: true,
    })
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "95efad36-fefd-4cf1-ae4b-a3bb5a61360c";
    window.$crisp.push(['do', 'chat:open']);

    (function() {
      var d = document;
      var s = d.createElement("script");

      s.src = "https://client.crisp.chat/l.js";
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();
  }

	render() {
		return (
			<div>
			  <div className="border-b border-gray-200 py-5 px-6 lg:px-40 mb-20">
					<div className="md:flex md:items-center md:justify-between">
					  <div className="flex-1 min-w-0">
					    <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9 sm:truncate">
					      <span role="img" aria-label="welcome">👋</span> Welcome to Investor Scout
					    </h2>
					  </div>
					  <div className="mt-4 flex md:mt-0 md:ml-4">
				     	<button onClick={ ()=> this.handleCrispClick() } type="button" className="text-sm bg-white hover:text-gray-900 text-gray-700 font-semibold py-2 px-4 border border-gray-200 rounded-lg shadow-sm transition ease-in-out duration-150 inline-flex items-center">
								<svg className="fill-current opacity-50 w-5 h-5 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
									<path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-2 0c0 .993-.241 1.929-.668 2.754l-1.524-1.525a3.997 3.997 0 00.078-2.183l1.562-1.562C15.802 8.249 16 9.1 16 10zm-5.165 3.913l1.58 1.58A5.98 5.98 0 0110 16a5.976 5.976 0 01-2.516-.552l1.562-1.562a4.006 4.006 0 001.789.027zm-4.677-2.796a4.002 4.002 0 01-.041-2.08l-.08.08-1.53-1.533A5.98 5.98 0 004 10c0 .954.223 1.856.619 2.657l1.54-1.54zm1.088-6.45A5.974 5.974 0 0110 4c.954 0 1.856.223 2.657.619l-1.54 1.54a4.002 4.002 0 00-2.346.033L7.246 4.668zM12 10a2 2 0 11-4 0 2 2 0 014 0z" clipRule="evenodd" />
								</svg>
					     	Support
					    </button>
			      	{(this.props.subscription && this.props.subscription.stripeSubscriptionId) !== "appsumo" ? (
			      		<NavLink to="/pricing" className="ml-3 text-sm bg-blue-1000 hover:opacity-75 text-white font-semibold py-2 px-4 border border-gray-200 rounded-lg shadow-sm transition ease-in-out duration-150 inline-flex items-center">
									<svg className="fill-current opacity-50 w-5 h-5 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
									  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
									</svg>
					      	Upgrade
					    	</NavLink>
					    ) : <span /> }
				  	</div>
					</div>
			 	</div>
				<div className="max-w-7xl mx-auto mt-4 px-4 sm:px-6 lg:px-8">
				  <div className="max-w-3xl mx-auto">
						<div className="bg-white overflow-hidden shadow-sm border border-gray-200 rounded-lg">
						  <div className="px-4 py-5 sm:p-6">
						    <h3 className="text-lg leading-6 font-semibold text-gray-900 flex">
								<svg className="fill-current opacity-50 w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
								  <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
								</svg>
						      Search through 37k+ investors
						    </h3>
						    <div className="mt-2 sm:flex sm:items-start sm:justify-between">
						      <div className="max-w-xl text-sm leading-5 text-gray-500">
						        <p>
						          Investor Scout consists of over 37,000 investors based all over the world, investing in every business stage from angel to LBO.
						        </p>
						      </div>
						      <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
						         <NavLink to="/investors-dashboard" className="text-sm bg-white hover:text-gray-900 text-gray-700 font-semibold py-2 px-4 border border-gray-200 rounded-lg shadow-sm transition ease-in-out duration-150 inline-flex items-center">
											<svg className="fill-current opacity-50 w-5 h-5 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
												<path d="M9 9a2 2 0 114 0 2 2 0 01-4 0z" />
												<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a4 4 0 00-3.446 6.032l-2.261 2.26a1 1 0 101.414 1.415l2.261-2.261A4 4 0 1011 5z" clipRule="evenodd" />
											</svg>
											Search investors
					          </NavLink>
						      </div>
						    </div>
						  </div>
						</div>
				  </div>
				</div>
				<div className="max-w-7xl mx-auto mt-4 px-4 sm:px-6 lg:px-8">
				  <div className="max-w-3xl mx-auto">
						<div className="bg-white overflow-hidden shadow-sm border border-gray-200 rounded-lg">
						  <div className="px-4 py-5 sm:p-6">
						    <h3 className="text-lg leading-6 font-semibold text-gray-900 flex">
									<svg className="fill-current opacity-50 w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
									  <path fillRule="evenodd" d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z" clipRule="evenodd" />
									  <path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z" />
									</svg>
						      Search through 8k+ investment funds
						    </h3>
						    <div className="mt-2 sm:flex sm:items-start sm:justify-between">
						      <div className="max-w-xl text-sm leading-5 text-gray-500">
						        <p>
						          Search through 8k+ venture capital funds, private equity funds, startup studios, and more!
						        </p>
						      </div>
									<div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
						         <NavLink to="/funds-dashboard" className="text-sm bg-white hover:text-gray-900 text-gray-700 font-semibold py-2 px-4 border border-gray-200 rounded-lg shadow-sm transition ease-in-out duration-150 inline-flex items-center">
											<svg className="fill-current opacity-50 w-5 h-5 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
												<path d="M9 9a2 2 0 114 0 2 2 0 01-4 0z" />
												<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a4 4 0 00-3.446 6.032l-2.261 2.26a1 1 0 101.414 1.415l2.261-2.261A4 4 0 1011 5z" clipRule="evenodd" />
											</svg>
											Search funds
					          </NavLink>
						      </div>
						    </div>
						  </div>
						</div>
				  </div>
				</div>
				<div className="max-w-7xl mx-auto mt-4 px-4 sm:px-6 lg:px-8">
				  <div className="max-w-3xl mx-auto">
						<div className="bg-white overflow-hidden shadow-sm border border-gray-200 rounded-lg">
						  <div className="px-4 py-5 sm:p-6">
						    <h3 className="text-lg leading-6 font-medium text-gray-900 flex">
									<svg className="fill-current opacity-50 w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
										<path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z" />
									</svg>
						      Manage lists
						    </h3>
						    <div className="mt-2 sm:flex sm:items-start sm:justify-between">
						      <div className="max-w-xl text-sm leading-5 text-gray-500">
						        <p>
						          Save your favorite investors to lists, then download their contact info to CSV to send them emails.
						        </p>
						      </div>
									<div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
						         <NavLink to="/lists" className="text-sm bg-white hover:text-gray-900 text-gray-700 font-semibold py-2 px-4 border border-gray-200 rounded-lg shadow-sm transition ease-in-out duration-150 inline-flex items-center">
											<svg className="fill-current opacity-50 w-5 h-5 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
											  <path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z" />
											</svg>
											Manage lists
					          </NavLink>
						      </div>
						    </div>
						  </div>
						</div>
				  </div>
				</div>
			</div>
		);
	}
}

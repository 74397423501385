import React from 'react'

const Loading = () => (
  <div className="max-w-7xl py-10 mx-auto px-4 sm:px-6 lg:px-8">
    <div className="grid grid-cols-6 gap-4">
        <div className="col-span-6">
          <div className="border border-gray-300 shadow rounded-md p-4 max-w-md w-full mx-auto">
            <div className="animate-pulse flex space-x-4">
              <div className="rounded-full bg-gray-400 h-12 w-12" />
              <div className="flex-1 space-y-4 py-1">
                <div className="h-4 bg-gray-400 rounded w-3/4" />
                <div className="space-y-2">
                  <div className="h-4 bg-gray-400 rounded" />
                  <div className="h-4 bg-gray-400 rounded w-5/6" />
                  <div className="h-4 bg-gray-400 rounded" />
                  <div className="h-4 bg-gray-400 rounded w-5/6" />
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
);

export default Loading;

import React from 'react';
import { NavLink } from 'react-router-dom';

const WhatYouGet = () => (
  <div className="pt-12 bg-white border-t border-gray-200">
    <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="lg:text-center">
        <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
          What do you get with Investor Scout?
        </h3>
        <p className="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">
          Why founders raise their rounds with Investor Scout's investor database
        </p>
      </div>

      <div className="mt-16">
        <ul className="md:grid md:grid-cols-2 md:col-gap-8 md:row-gap-10">
          <li>
            <div className="flex">
              <div className="flex-shrink-0">
                <div className="flex items-center justify-center h-12 w-12 rounded-lg bg-green-200 text-white">
                  <svg className="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
                  </svg>
                </div>
              </div>
              <div className="ml-4">
                <h4 className="text-lg leading-6 font-bold text-gray-900">37,000+ investors at your fingertips</h4>
                <p className="mt-2 text-base leading-6 text-gray-500">
                  Investor Scout is a comprehensive database of angel investors, venture capitalists, and investment funds you can use to quickly find & contact relevant investors
                </p>
              </div>
            </div>
          </li>
          <li className="mt-10 md:mt-0">
            <div className="flex">
              <div className="flex-shrink-0">
                <div className="flex items-center justify-center h-12 w-12 rounded-lg bg-green-200 text-white">
                  <svg className="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z" clipRule="evenodd" />
                  </svg>
                </div>
              </div>
              <div className="ml-4">
                <h4 className="text-lg leading-6 font-bold text-gray-900">Filter investors by their investment preferences</h4>
                <p className="mt-2 text-base leading-6 text-gray-500">
                  Filter through investors and funds based on investment preferences like location, industry, and check size
                </p>
              </div>
            </div>
          </li>
          <li className="mt-10 md:mt-0">
            <div className="flex">
              <div className="flex-shrink-0">
                <div className="flex items-center justify-center h-12 w-12 rounded-lg bg-green-200 text-white">
                  <svg className="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                  </svg>
                </div>
              </div>
              <div className="ml-4">
                <h4 className="text-lg leading-6 font-bold text-gray-900">Investor emails, phone numbers, and addresses</h4>
                <p className="mt-2 text-base leading-6 text-gray-500">
                  Investor Scout is one of the largest investor contact databases available, helping you spend less time researching and more time pitching
                </p>
              </div>
            </div>
          </li>
          <li className="mt-10 md:mt-0">
            <div className="flex">
              <div className="flex-shrink-0">
                <div className="flex items-center justify-center h-12 w-12 rounded-lg bg-green-200 text-white">
                  <svg className="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M5 2a1 1 0 011 1v1h1a1 1 0 010 2H6v1a1 1 0 01-2 0V6H3a1 1 0 010-2h1V3a1 1 0 011-1zm0 10a1 1 0 011 1v1h1a1 1 0 110 2H6v1a1 1 0 11-2 0v-1H3a1 1 0 110-2h1v-1a1 1 0 011-1zM12 2a1 1 0 01.967.744L14.146 7.2 17.5 9.134a1 1 0 010 1.732l-3.354 1.935-1.18 4.455a1 1 0 01-1.933 0L9.854 12.8 6.5 10.866a1 1 0 010-1.732l3.354-1.935 1.18-4.455A1 1 0 0112 2z" clipRule="evenodd" />
                  </svg>
                </div>
              </div>
              <div className="ml-4">
                <h4 className="text-lg leading-6 font-bold text-gray-900">Our investors come from funds like Sequoia, Y Combinator, and KPCB</h4>
                <p className="mt-2 text-base leading-6 text-gray-500">
                  Our investors represent thousands of funds investing in early and late stage companies
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div className="mt-10">
        <ul className="md:grid md:grid-cols-2 md:col-gap-8 md:row-gap-10">
          <li>
            <div className="flex">
              <div className="flex-shrink-0">
                <div className="flex items-center justify-center h-12 w-12 rounded-lg bg-green-200 text-white">
                  <svg className="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM4.332 8.027a6.012 6.012 0 011.912-2.706C6.512 5.73 6.974 6 7.5 6A1.5 1.5 0 019 7.5V8a2 2 0 004 0 2 2 0 011.523-1.943A5.977 5.977 0 0116 10c0 .34-.028.675-.083 1H15a2 2 0 00-2 2v2.197A5.973 5.973 0 0110 16v-2a2 2 0 00-2-2 2 2 0 01-2-2 2 2 0 00-1.668-1.973z" clipRule="evenodd" />
                  </svg>
                </div>
              </div>
              <div className="ml-4">
                <h4 className="text-lg leading-6 font-bold text-gray-900">Our investors invest in companies all over the world, from all over the world</h4>
                <p className="mt-2 text-base leading-6 text-gray-500">
                  Our investors are from every continent except Antarctica
                </p>
              </div>
            </div>
          </li>
          <li className="mt-10 md:mt-0">
            <div className="flex">
              <div className="flex-shrink-0">
                <div className="flex items-center justify-center h-12 w-12 rounded-lg bg-green-200 text-white">
                  <svg className="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z" clipRule="evenodd" />
                    <path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z" />
                  </svg>
                </div>
              </div>
              <div className="ml-4">
                <h4 className="text-lg leading-6 font-bold text-gray-900">Our investors invest in everything from consumer internet to fashion, from seed stage to LBO</h4>
                <p className="mt-2 text-base leading-6 text-gray-500">
                  Our investors have invested in tens of thousands of companies like Google and Apple
                </p>
              </div>
            </div>
          </li>
          <li className="mt-10 md:mt-0">
            <div className="flex">
              <div className="flex-shrink-0">
                <div className="flex items-center justify-center h-12 w-12 rounded-lg bg-green-200 text-white">
                  <svg className="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z" />
                  </svg>
                </div>
              </div>
              <div className="ml-4">
                <h4 className="text-lg leading-6 font-bold text-gray-900">Build lists of your favorite investors and funds, then export their contact info to CSV</h4>
                <p className="mt-2 text-base leading-6 text-gray-500">
                  With Investor Scout you can create investor lists and export their emails to CSV so you can pitch them your business
                </p>
              </div>
            </div>
          </li>
          <li className="mt-10 md:mt-0">
            <div className="flex">
              <div className="flex-shrink-0">
                <div className="flex items-center justify-center h-12 w-12 rounded-lg bg-green-200 text-white">
                  <svg className="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M2 6a2 2 0 012-2h5l2 2h5a2 2 0 012 2v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" />
                    <path stroke="#c6f6d5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 9v4m0 0l-2-2m2 2l2-2" />
                  </svg>
                </div>
              </div>
              <div className="ml-4">
                <h4 className="text-lg leading-6 font-bold text-gray-900">Export your investor lists to CSV</h4>
                <p className="mt-2 text-base leading-6 text-gray-500">
                  Once you build your list of investors, you can export their contact info to CSV with a single click
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div className="bg-gray-100 border-t border-gray-200 mt-16 mx-auto flex px-auto">
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 flex my-12">
        <a className="mx-2" href="https://www.producthunt.com/posts/investor-scout?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-investor-scout" target="_blank" rel="noopener noreferrer"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=142885&theme=dark" alt="Investor Scout - A database of 45k+ investors to raise your seed round 💰 | Product Hunt" style={{width: '250px', height: '54px'}} width="250" height="54" /></a>
        <a className="mx-2" href="https://www.producthunt.com/posts/investor-scout?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-investor-scout" target="_blank" rel="noopener noreferrer"><img src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=142885&theme=dark&period=daily" alt="Investor Scout - A database of 45k+ investors to raise your seed round 💰 | Product Hunt" style={{width: '250px', height: '54px'}} width="250" height="54" /></a>
        <a className="mx-2" href="https://www.producthunt.com/posts/investor-scout?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-investor-scout" target="_blank" rel="noopener noreferrer"><img src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=142885&theme=dark&period=weekly" alt="Investor Scout - A database of 45k+ investors to raise your seed round 💰 | Product Hunt" style={{width: '250px', height: '54px'}} width="250" height="54" /></a>
      </div>
    </div>
    <div className="bg-white border-t border-b border-gray-200">
      <div className="max-w-screen-xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
          Ready to raise your round?
          <br />
          Get started for free today.
        </h2>
        <div className="mt-8 flex justify-center">
          <div className="inline-flex rounded-lg shadow-sm">
            <NavLink to="/signup" className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-bold rounded-lg text-white bg-investor-blue hover:opacity-75 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
              Get started for free
              <svg className="fill-current opacity-50 w-5 h-5 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z" clipRule="evenodd" />
              </svg>
            </NavLink>
          </div>
          <div className="ml-3 inline-flex rounded-lg">
            <NavLink to="/pricing" className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-bold rounded-lg shadow-sm border border-gray-200 text-gray-900 bg-white focus:outline-none focus:shadow-outline focus:border-indigo-300 transition duration-150 ease-in-out">
              See pricing
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default WhatYouGet;

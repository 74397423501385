import React from 'react';
import FirebaseAuth from './FirebaseAuth';
import { FirestoreCollection } from 'react-firestore';

const FirebaseUser = ({ children }) => (
  <FirebaseAuth>
    { ({ isLoading, error, auth }) => {

      if (error || isLoading || !auth) {
        return children({
          error,
          isLoading,
          subscription: null,
        })
      }

      return <FirestoreCollection
        path="users"
        filter={['email', '==', auth.email]}
      >
        { ({ error, isLoading, data }) => {
          return children({
            error,
            isLoading,
            auth,
            stripeCustomerId: data.length > 0 ? data[0].stripeCustomerId : null,
            email: data.length > 0 ? data[0].email : null,
            subscribed: data.length > 0 ? data[0].subscribed : null,
          })
        }}
      </FirestoreCollection>

    }}
  </FirebaseAuth>
)

export default FirebaseUser

import Firebase from 'firebase/app';

const deleteEntry = async (listId, entryId, dataType, objectID) => {
	var docRef = Firebase.firestore().collection("lists").doc(listId);

	await removeEntry(listId, entryId);
	await updateIndex(docRef, dataType, objectID);
};

const removeEntry = (listId, entryId) => {
	console.log("Entering removeEntry function")

  return Firebase.firestore()
    .collection(`lists/${listId}/entries`)
    .doc(entryId)
    .delete()
    .catch( error => {
      alert(`Whoops, couldn't delete the list: ${error.message}`)
    })
};

const updateIndex = (docRef, dataType, objectID) => {

	return docRef.get().then(function(doc) {
    if (doc.exists) {
    	if (dataType === "Fund") {
    		var fundIndex = doc.data().fundIndex;
    		const index = fundIndex.indexOf(objectID);
    		fundIndex.splice(index, 1);
    		return docRef.update({
			    fundIndex,
			    updatedOn: Firebase.firestore.Timestamp.now(),
				})
				.then(function() {
			    console.log("Document successfully updated!");
				})
				.catch(function(error) {
			    console.error("Error updating document: ", error);
				});
    	} else if (dataType === "Investor") {
    		var investorIndex = doc.data().investorIndex;
    		const index = investorIndex.indexOf(objectID);
    		investorIndex.splice(index, 1);
    		return docRef.update({
    			investorIndex,
    			updatedOn: Firebase.firestore.Timestamp.now(),
    		})
				.then(function() {
			    console.log("Document successfully updated!");
				})
				.catch(function(error) {
			    console.error("Error updating document: ", error);
				});
    	}
    } else {
      console.log("No such document!");
    }
	}).catch(function(error) {
    console.log("Error getting document:", error);
	});
};

export default deleteEntry;

import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import Firebase from 'firebase/app';
import { NavLink } from 'react-router-dom';

import createUser from '../../actions/createUser';

export default function HookSignUp({ props }) {
  const { register, errors, handleSubmit } = useForm();
  const [error, setError] = useState("");

  const onSubmit = data => {

    if (data.passwordOne === data.passwordTwo) {
      let referral = (window.Rewardful && window.Rewardful.referral) ? window.Rewardful.referral : "";
      Firebase.auth().createUserWithEmailAndPassword(data.email, data.passwordOne)
        .then( () => {
          var authUser = Firebase.auth().currentUser;
          console.log(`signed up as ${authUser.email}`);
          console.log(authUser.uid);
          if (authUser) {
            return createUser(authUser, referral)
              .then( () => {
                props.history.push("/dashboard");
              })
              .catch( error => {
                setError(error)
              });
          }
        })
        .catch( error => {
          setError(error)
        });
    }
  }

  return (          
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700">
          Email address
        </label>
        <div className="mt-1 rounded-md shadow-sm">
          <input
            name="email"
            id="email"
            type="email"
            ref={register({ 
              required: "This input is required.",
              minLength: {
                value: 11,
                message: "This input must exceed 10 characters"
              },
              maxLength: {
                value: 129,
                message: "This input exceeds 128 characters"
              }
            })}
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          />   
        </div>
        {errors.email && <p className="mt-2 text-sm text-red-600" id="email-error">{errors.email.message}</p>}
      </div>

      <div className="mt-6">
        <label htmlFor="password" className="block text-sm font-medium leading-5 text-gray-700">
          Password
        </label>
        <div className="mt-1 rounded-md shadow-sm">
          <input
            name="passwordOne"
            id="passwordOne"
            type="password"
            ref={register({ 
              required: "This input is required.",
              maxLength: {
                value: 129,
                message: "This input exceeds 128 characters"
              }
            })}
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          />
        </div>
        {errors.passwordOne && <p className="mt-2 text-sm text-red-600" id="password-error">{errors.passwordOne.message}</p>}
      </div>

      <div className="mt-6">
        <label htmlFor="password" className="block text-sm font-medium leading-5 text-gray-700">
          Confirm password
        </label>
        <div className="mt-1 rounded-md shadow-sm">
          <input
            name="passwordTwo"
            id="passwordTwo"
            type="password"
            ref={register({ 
              required: "This input is required.",
              maxLength: {
                value: 129,
                message: "This input exceeds 128 characters"
              }
            })}
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          />
        </div>
        {errors.passwordTwo && <p className="mt-2 text-sm text-red-600" id="password-error">{errors.passwordTwo.message}</p>}
      </div>

      <div className="mt-6">
        <span className="block w-full rounded-md shadow-sm">
          <button type="submit" className="w-full flex justify-center py-3 px-5 border border-transparent text-base font-bold rounded-lg text-white bg-investor-blue hover:opacity-75 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
            Get started for free now
            <svg className="fill-current opacity-25 w-6 h-6 ml-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z" clipRule="evenodd" />
            </svg>
          </button>
        </span>
        {error && <p className="mt-2 text-sm text-red-600" id="error">{error.message}</p>}
      </div>
    </form>
  )
}

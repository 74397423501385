import React from 'react';
import Firebase from 'firebase/app';

import Loading from '../misc/Loading';
import FundAllData from './fundAllData';
import InvestorAllData from './investorAllData';

class ListContact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    	item: {},
      listEntries:[],
      list: {},
      isLoading: true,
      plan: this.props.plan,
    };
    this.getDocument = this.getDocument.bind(this);
  }

  componentDidMount() {
    this.getDocument();
	}

  getDocument = () => {
    var db = Firebase.firestore();
    var listData = db.collection('lists')
      .where('createdBy', '==', this.props.subscription.user).where('slug', '==', this.props.match.params.slug)
      .get()
      .then(documentSnapshots => {
        documentSnapshots.forEach(doc => {
          this.setState({
            list: doc.data(),
            listId: doc.id,
            listEntries: db.collection('lists').doc(doc.id).collection('entries')
          })
        })
      })
      .then( () => this.getFirstItems())
  };

  getFirstItems = () => {
    var listSize = this.state.listEntries
      .get()
      .then(snap => {
        this.setState({
          totalItemCount: snap.size,
        })
      })
    var listEntries = this.state.listEntries
      .where('objectID', '==', this.props.match.params.objectID)
      .get()
      .then( documentSnapshots => {
      	var item = documentSnapshots.docs[0];
        return this.setItem(item)
      })
  }

  setItem = item => {
    this.setState({
      item: item.data(),
      isLoading: false,
    })
  }

  render() {
    const { item, isLoading, plan } = this.state;

    if (isLoading) {
    	return <Loading />
    } else {
	    return (
	      <div className="max-w-7xl mt-12">
					{ item.dataType === "Fund" ? <FundAllData hit={item} plan={plan} /> : <InvestorAllData hit={item} plan={plan} /> }
		    </div>
	    );
		}
  }
}

export default ListContact;

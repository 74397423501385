import React from "react";
import { useForm } from "react-hook-form";

import createList from '../../actions/createList';
import updateList from '../../actions/updateList';
import deleteList from '../../actions/deleteList';

export default function ListHookForm({ reDirect, activateModal, getDocument, fromDash, listId, list, isUpdate }) {
  const { register, handleSubmit, errors, reset } = useForm();

  const onSubmit = (data, e) => {
    if (isUpdate) {
      updateList(listId, data);
      reloadPage();
    } else {
      createList(data);
    }
    e.target.reset();
    cancel();
  }

  const callDeleteList = () => {
    console.log("Deleting list");
    deleteList(listId)
      .then( () => reDirect());
  };

  const cancel = () =>
    activateModal();

  const reloadPage = () =>
    getDocument();

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
      <div>
        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
          <svg className="h-6 w-6 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
          </svg>
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
            {isUpdate ? "Edit list" : "Create new list"}
          </h3>
          <div className="mt-2">
            <div className="flex justify-between">
              <label htmlFor="name" className="block text-sm font-medium leading-5 text-gray-700">Name</label>
              <span className="text-sm leading-5 text-red-400">*Required</span>
            </div>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                id="name"
                name="name"
                defaultValue={isUpdate ? list.name : ""}
                ref={register({ required: "This input is required." })}
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                placeholder="Seed round"
              />
            </div>
            {errors.name && <p className="mt-2 text-sm text-red-600" id="email-error">{errors.name.message}</p>}
          </div>
          <div className="mt-2">
            <div className="flex justify-between">
              <label htmlFor="description" className="block text-sm font-medium leading-5 text-gray-700">Description</label>
              <span className="text-sm leading-5 text-gray-500">optional</span>
            </div>
            <div className="mt-1 relative rounded-md shadow-sm">
              <textarea
                id="description"
                name="description"
                defaultValue={isUpdate ? list.description : ""}
                ref={register} 
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
        <span className="flex w-full rounded-md shadow-sm sm:col-start-2">
          <button
            type="submit"
            className="inline-flex justify-center w-full rounded-lg border border-transparent px-4 py-2 bg-blue-1000 text-base leading-6 font-medium text-white shadow-sm hover:opacity-75 focus:outline-none transition ease-in-out duration-150 sm:text-sm sm:leading-5"
          >
            {isUpdate ? "Update" : "Create"}
          </button>
        </span>
        <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1">
          <button
            onClick={() => {reset(); cancel();}}
            type="button"
            className="inline-flex justify-center w-full rounded-lg border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
          >
            Cancel
          </button>
        </span>
      </div>
      {isUpdate ? (
        <div className="mt-8 sm:mt-6 border-t border-gray-200 space-y-3">
          <div className="flex mt-4">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3 text-center">
              <p className="text-sm leading-5 text-red-700 text-center">
                Warning: you cannot undo this action!
              </p>
            </div>
          </div>
          <button
            onClick={callDeleteList}
            type="button"
            className="inline-flex justify-center w-full rounded-lg border border-transparent px-4 py-2 bg-red-1000 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-400 focus:outline-none focus:border-red-600 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5"
          >
            Delete List
          </button>
        </div>
      ) : <span /> }
    </form>
  );
}
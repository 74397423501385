import React, { PureComponent } from 'react';
import Firebase from 'firebase/app';
import Pagination from 'react-js-pagination';
import Slugify from 'slugify';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Placeholder from '../../assets/placeholder.png';
import Cta from './cta';
import Footer from '../Landing/footer';

class Investors extends PureComponent {
	constructor(props) {
    super(props);
    this.state = {
      dbItems: [],
      currentPage: this.props.location.state ? this.props.location.state.page : 1,
      itemsPerPage: 25,
      totalItemCount: 40000,
      //subject to change depending on # of data in dB
      activePage: this.props.location.state ? this.props.location.state.page : 1,
    }
    this.handlePageChange = this.handlePageChange.bind(this);
    this.getItems = this.getItems.bind(this);
	}

	handlePageChange = (pageNumber) => {
    console.log(`active page is ${pageNumber}`);
    this.setState({
    	activePage: pageNumber,
    	currentPage: pageNumber,
    });
	}

	getItems = () => {
		console.log("first invoked")
	  const {currentPage, itemsPerPage} = this.state
	  const startAt = (currentPage * itemsPerPage - itemsPerPage)
    var db = Firebase.firestore();
    var first = db.collection('investors')
      .orderBy("index")
      .startAt(startAt)
	  	.limit(itemsPerPage)

    first.get().then( documentSnapshots => {
  	  var items = documentSnapshots.docs.map(doc => doc.data())
  	  return this.setState({
        dbItems: items,
      })
    })
	}

	componentDidMount() {
    this.getItems()
	}

	componentDidUpdate(prevProps, prevState) {
    const isDifferentPage = this.state.currentPage !== prevState.currentPage
    if (isDifferentPage) this.getItems()
	}

  render() {
    return (
    	<div>
				<Helmet>
					<title>Find investors with Investor Scout's database of 37,000+ investors</title>
					<meta name="description" content="Investor Scout is a database of 37,000+ investor emails and phone numbers to help you find investors to feature your business" />

					<meta itemprop="name" content="Find investors with Investor Scout's database of 37,000+ investors" />
					<meta itemprop="description" content="Investor Scout is a database of almost 37,000+ investor emails and phone numbers to help you find investors to feature your business" />
					<meta itemprop="image" content="https://i.imgur.com/uOpUy46.png" />

					<meta property="og:url" content={"https://investorscout.co/" + `investors/`+ this.props.match.params.slug} />
					<meta property="og:type" content="website" />
					<meta property="og:title" content="Find investors with Investor Scout's database of 37,000+ investors" />
					<meta property="og:description" content="Investor Scout is a database of almost 37,000+ investor emails and phone numbers to help you find investors to feature your business" />
					<meta property="og:image" content="https://i.imgur.com/uOpUy46.png" />

					<meta name="twitter:card" content="summary_large_image" />
					<meta name="twitter:title" content="Find investors with Investor Scout's database of 37,000+ investors" />
					<meta name="twitter:description" content="Investor Scout is a database of almost 37,000+ investor emails and phone numbers to help you find investors to feature your business" />
					<meta name="twitter:image" content="https://i.imgur.com/uOpUy46.png" />
					<script type="text/javascript" src="https://load.fomo.com/api/v1/6drd7n4A3I20_l_e4M-Wjw/load.js" async></script>
				</Helmet>

				<Cta />

				<div className="container mx-auto mb-24">
          {this.state.dbItems.map((investor, index) => {
          	var link = Slugify(investor.name, {lower: true});
          	var id = investor.objectID;
          	var numberInvestments = 0;
          	var moreNumberInvestments = 0;
          	var numberMarkets = 0;
          	var moreNumberMarkets = 0;
          	if (investor.investments) {
          		numberInvestments = investor.investments.length;
            	moreNumberInvestments = numberInvestments - 3;
          	}
          	if (investor.markets) {
          		numberMarkets = investor.markets.length;
            	moreNumberMarkets = numberMarkets - 3;
          	}
            return (
              <div key={index} className="bg-white my-10 py-6 px-10 lg:mx-20 overflow-hidden sm:rounded-lg shadow-sm border border-gray-200">
              	<Link to={{
              		pathname: `/investors/${link}`,
              		state: { page: this.state.currentPage, id: id }
              	}}>
								  <div className="-ml-4 -mt-4 flex items-center flex-wrap sm:flex-no-wrap">
								    <div className="ml-4 mt-4">
									    <img className="w-24 h-24 flex-shrink-0 mx-auto bg-black rounded-full" src={investor.avatar ? investor.avatar : Placeholder} alt="" />
								    </div>
								    <div className="ml-4 flex-shrink-0">
									    <h3 className="text-lg leading-6 font-bold text-gray-900">
									      {investor.name}
									    </h3>
									    <div className="mt-1 max-w-2xl text-sm leading-5 text-gray-600">
                        <span>
                          {(investor.has_company && typeof investor.company === "string") ? 
                          investor.company : (investor.has_company && typeof investor.company === "object") ?
                          investor.company["name"] : "Investor" }
                        </span>											    
                       </div>
									    <div className="flex mt-1 max-w-2xl text-sm leading-5 text-gray-600">
										    <svg className="w-5 h-5 text-gray-400 mr-1" viewBox="0 0 20 20" fill="currentColor">
												  <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
												</svg>
                        <span className="px-2 py-1 text-teal-800 text-xs leading-4 font-medium bg-teal-100 rounded">{investor.city}</span>
									    </div>
								    </div>
								  </div>
								  <div className="py-2">
										{(investor.has_investments && numberInvestments < 4 ) ? (
											investor.investments.map((investment, index) =>
												<a key={index} href={investment["link"]} target="_blank" rel="noopener noreferrer" className="inline-flex mx-1 items-center mt-2 px-2 py-1 rounded-md text-sm font-medium leading-5 bg-yellow-100 text-yellow-800" >{investment["name"]}</a>
											)
										) : (
											<span />
										)}
										{(investor.has_investments && numberInvestments > 3 ) ? (
											investor.investments.slice(0,3).map((investment, index) =>
												<a key={index} href={investment["link"]} target="_blank" rel="noopener noreferrer" className="inline-flex mx-1 items-center mt-2 px-2 py-1 rounded-md text-sm font-medium leading-5 bg-yellow-100 text-yellow-800">{investment["name"]}</a>
											)
										) : (
											<span />
										)}
										{(investor.has_investments && numberInvestments > 3 ) ?
											<a href={"/investors/" + link} className="inline-flex mx-1 items-center mt-2 px-2 py-1 rounded-md text-sm font-medium leading-5 bg-yellow-100 text-yellow-800" >
												{"See " + moreNumberInvestments + " more investments"}
											</a> : <span /> }
									</div>
									<div className="py-2">
										{(investor.markets && numberMarkets < 4) ? (
			                investor.markets.map((market, index) =>
			                  <div key={index} className="inline-flex mx-1 items-center mt-2 px-2 py-1 rounded-md text-sm font-medium leading-5 bg-indigo-100 text-indigo-800">
			                    {market}
			                  </div>
			                )
			              ) : <span /> }
			              {(investor.markets && numberMarkets > 3) ? (
			                investor.markets.slice(0,3).map((market, index) =>
			                  <div key={index} className="inline-flex mx-1 items-center mt-2 px-2 py-1 rounded-md text-sm font-medium leading-5 bg-indigo-100 text-indigo-800">
			                    {market}
			                  </div>
			                )
			              ) : <span /> }
										{(investor.has_investments && numberMarkets > 3 ) ?
										<a href={"/investors/" + link} className="inline-flex mx-1 items-center mt-2 px-2 py-1 rounded-md text-sm font-medium leading-5 bg-indigo-100 text-indigo-800">
											{"See " + moreNumberMarkets + " more market interests"}
										</a> : <span /> }
									</div>
                </Link>
							</div>
    				)
					})}
					<div className="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0" role="navigation" aria-label="pagination">
						<Pagination
							activePage={this.state.activePage}
							itemsCountPerPage={this.state.itemsPerPage}
							totalItemsCount={this.state.totalItemCount}
							pageRangeDisplayed={10}
							onChange={this.handlePageChange}
							firstPageText="First"
							lastPageText="Last"
							innerClass="hidden md:flex m-auto"
							linkClass="-mt-px border-t-2 border-transparent pt-4 px-4 inline-flex items-center text-sm leading-5 font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-400 transition ease-in-out duration-150"
							activeLinkClass="-mt-px border-t-2 border-indigo-500 pt-4 px-4 inline-flex items-center text-sm leading-5 font-medium text-indigo-600 focus:outline-none focus:text-indigo-800 focus:border-indigo-700 transition ease-in-out duration-150"
							className="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0"
						/>
					</div>
				</div>
        <Footer />
    	</div>
		)
  }
}

export default Investors;

import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useForm } from "react-hook-form";
import Firebase from 'firebase/app';

import Logo from '../../assets/investor-scout-3-logo.svg'

export default function PasswordForgetLink(props) {
  const { register, errors, handleSubmit } = useForm();
  const [error, setError] = useState("");

  const onSubmit = data => {
    Firebase.auth().sendPasswordResetEmail(data.email)
      .then(() => {
        props.history.push("/signin");
      })
      .catch(error => {
        setError(error)
      });
  }

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto text-center sm:w-full sm:max-w-md">
        <img className="mx-auto h-12 w-auto" src={Logo} alt="InvestorScout" />
        <h2 className="mt-6 text-3xl leading-9 font-extrabold text-gray-900">
          Reset Password
        </h2>
        <p className="mt-2 text-sm leading-5 text-gray-600 max-w">
          <span>Or </span>          
          <NavLink to="/signup" className="font-medium text-blue-1000 hover:text-blue-500 focus:outline-none focus:underline transition ease-in-out duration-150">
            sign up now
          </NavLink>
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700">
                Email address
              </label>
              <div className="mt-1 rounded-md shadow-sm">
                <input
                  name="email"
                  id="email"
                  type="email"
                  ref={register({ 
                    required: "This input is required.",
                    minLength: {
                      value: 11,
                      message: "This input must exceed 10 characters"
                    },
                    maxLength: {
                      value: 129,
                      message: "This input exceeds 128 characters"
                    }
                  })}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />   
              </div>
              {errors.email && <p className="mt-2 text-sm text-red-600" id="email-error">{errors.email.message}</p>}
            </div>

            <div className="mt-6">
              <span className="block w-full rounded-md shadow-sm">
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-1000 hover:opacity-75 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out"
                >
                  Reset my password
                </button>
              </span>
              {error && <p className="mt-2 text-sm text-red-600" id="error">{error.message}</p>}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

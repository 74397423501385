import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';

import logOut from '../../actions/logOut';
import Transition from '../../styles/Transition.js';

import Logo from '../../assets/investor-scout-3-logo-full.svg'

class DashNav extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isOn: false,
			dropdownOn: false,
			path: this.props.path,
			mobileInactiveClass: "block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition duration-150 ease-in-out",
			mobileActiveClass: "block pl-3 pr-4 py-2 border-l-4 border-indigo-500 text-base font-medium text-indigo-700 bg-indigo-50 focus:outline-none focus:text-indigo-800 focus:bg-indigo-100 focus:border-indigo-700 transition duration-150 ease-in-out",
			activeClass: "inline-flex items-center px-1 pt-1 border-b-2 border-investor-blue text-sm font-bold leading-5 text-gray-900 transition duration-150 ease-in-out",
			inactiveClass: "inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-900 hover:text-gray-700 hover:border-gray-300 transition duration-150 ease-in-out",
		}
		this.setDropdown = this.setDropdown.bind(this);
		this.setMobile = this.setMobile.bind(this);
	}

	setDropdown() {
    this.setState(prevState => ({
      dropdownOn: !prevState.dropdownOn,
    }));
	}

	setMobile() {
   this.setState(prevState => ({
      isOn: !prevState.isOn,
    }));
	}

	render() {
		const { isOn, dropdownOn, path, activeClass, inactiveClass, mobileActiveClass, mobileInactiveClass}  = this.state;
		const { history } = this.props;
		return (
			<div>
			  <nav className="bg-white shadow-sm">
			    <div className="max-w-7xl mx-auto px-6 lg:px-40">
			      <div className="flex justify-between h-16">
			        <div className="flex">
			          <div className="flex-shrink-0 flex items-center">
									<NavLink to="/dashboard">
					          <img className="hidden lg:block h-10 w-auto" src={Logo} alt="Investor Scout logo" />
									</NavLink>
			          </div>
			          <div className="hidden sm:ml-6 space-x-8 sm:flex">
				          <NavLink to="/dashboard" activeClassName={activeClass} className={inactiveClass}>
										<svg className="outline-current opacity-50 w-5 h-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
											<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
										</svg>
										Home
									</NavLink>
			            <NavLink to="/investors-dashboard" activeClassName={activeClass} className={inactiveClass}>
										<svg className="outline-current opacity-50 w-5 h-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
										  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
										</svg>
										Investors
									</NavLink>
			            <NavLink to="/funds-dashboard" activeClassName={activeClass} className={inactiveClass}>
										<svg className="outline-current opacity-50 w-5 h-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
										  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
										</svg>
										Funds
									</NavLink>
			            <NavLink to="/lists" activeClassName={activeClass} className={inactiveClass}>
										<svg className="outline-current opacity-50 w-5 h-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
										  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
										</svg>
										Lists
									</NavLink>
			          </div>
			        </div>
			        <div className="hidden sm:ml-6 sm:flex sm:items-center">
			          <div className="ml-3 relative">
			            <div>
			              <button onClick={() => this.setDropdown()} className="flex text-sm border-2 border-transparent rounded-full focus:outline-none focus:border-gray-300 transition duration-150 ease-in-out" id="user-menu" aria-label="User menu" aria-haspopup="true">
			                <span className="inline-block h-8 w-8 rounded-full overflow-hidden bg-gray-100">
											  <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
											    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
											  </svg>
											</span>
			              </button>
			            </div>
                  <Transition
		                show={dropdownOn}
		                enter="transition ease-out duration-200"
		                enterFrom="transform opacity-0 scale-95"
		                enterTo="transform opacity-100 scale-100"
		                leave="transition ease-in duration-75"
		                leaveFrom="transform opacity-100 scale-100"
		                leaveTo="transform opacity-0 scale-95"
		              >
				            <div className="origin-top-right z-10 absolute right-0 mt-2 w-48 rounded-md shadow-lg">
				              <div className="py-1 rounded-md bg-white shadow-xs">
				                <NavLink onClick={() => this.setDropdown()} to="/account" className="block px-4 py-2 text-sm mb-0 leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out">
													<svg className="opacity-50 fill-current outline-current mr-2 h-5 w-5 inline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
													  <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clipRule="evenodd" />
													</svg>
													Account
												</NavLink>
				                <div className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out cursor-pointer" onClick={() => logOut().then( () => history.push(`/`)) }>
													<svg className="opacity-50 fill-current outline-current mr-2 h-5 w-5 inline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
													  <path fillRule="evenodd" d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z" clipRule="evenodd" />
													</svg>
													Log out
												</div>
				              </div>
				            </div>
				          </Transition>
			          </div>
			        </div>
			        <div className="-mr-2 flex items-center sm:hidden">
			          <button onClick={() => this.setMobile()} className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
			            <svg className={`${isOn ? 'hidden' : 'inline'} h-6 w-6`} stroke="currentColor" fill="none" viewBox="0 0 24 24">
			              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
			            </svg>
			            <svg className={`${isOn ? 'inline' : 'hidden'} h-6 w-6`} stroke="currentColor" fill="none" viewBox="0 0 24 24">
			              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
			            </svg>
			          </button>
			        </div>
			      </div>
			    </div>

			    <div className={`${isOn ? 'inline' : 'hidden'} sm:hidden`}>
			      <div className="pt-2 pb-3 space-y-1">
				      <NavLink to="/dashboard" activeClassName={mobileActiveClass} className={mobileInactiveClass}>Home</NavLink>
			        <NavLink to="/investors-dashboard" activeClassName={mobileActiveClass} className={mobileInactiveClass}>Investors</NavLink>
			        <NavLink to="/funds-dashboard" activeClassName={mobileActiveClass} className={mobileInactiveClass}>Funds</NavLink>
			        <NavLink to="/lists" activeClassName={mobileActiveClass} className={mobileInactiveClass}>Lists</NavLink>
			      </div>
			      <div className="pt-4 pb-3 border-t border-gray-200">
			        <div className="mt-3 space-y-1" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
			          <NavLink to="/account" activeClassName={mobileActiveClass} className={mobileInactiveClass}>Account</NavLink>
			          <div onClick={() => logOut().then( () => history.push(`/`)) } className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 focus:outline-none focus:text-gray-800 focus:bg-gray-100 transition duration-150 ease-in-out" role="menuitem">Log out</div>
			        </div>
			      </div>
			    </div>
			  </nav>
			</div>
		);
	}
}

export default withRouter(DashNav);

import Firebase from 'firebase/app';

const updateSubscription = (subscriptionId, plan) => {
  return Firebase.firestore()
    .collection('subscriptions')
    .doc(subscriptionId)
    .update({
    	"plan": plan,
    	"updatedOn": Firebase.firestore.FieldValue.serverTimestamp(),
    })
    .catch( error => {
      alert(`Whoops, couldn't update the subscription: ${error.message}`)
    })
}

export default updateSubscription;

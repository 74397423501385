import Firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import "firebase/functions";
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import App from './views/App';

// connects our app to firebase 
const dbConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
}
Firebase.initializeApp(dbConfig)

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID)

// render the App component to our document root with React
ReactDOM.render(<App />, document.getElementById('root'));

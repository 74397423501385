import React from 'react';
import { NavLink } from 'react-router-dom';

export default function FundAllData({ hit, plan }) {

  return (
		<div className="bg-white mb-10 overflow-hidden sm:rounded-lg sm:mx-40 border border-gray-200 shadow-sm">
			<div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
			  <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-no-wrap">
			    <div className="ml-4 mt-4">
				    <h3 className="text-lg leading-6 font-medium text-gray-900">
				      {hit.officeFirmName}
				    </h3>
				    <div className="mt-1 max-w-2xl text-sm leading-5 text-gray-600">
				      {hit.typeOfFirm} in {hit.officeCity} {hit.officeState} {hit.officeCountry}
				    </div>
			    </div>
			  </div>
			</div>

			<div className="px-4 py-5 sm:px-6">
		    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
		      <div className="sm:col-span-1">
		        <dt className="text-sm leading-5 font-medium text-gray-500">
		          About
		        </dt>
		        <dd className="mt-1 text-sm leading-5 text-gray-900">
		          <span>{hit.numberOfOffices} office(s)</span><br />
		          <span>{hit.numberOfInvestors} investor(s)</span><br />
		          <span>founded in {hit.yearFounded}</span><br />
		          <span>{hit.genderRatio}</span>
		        </dd>
		      </div>
		      <div className="sm:col-span-1">
		        <dt className="text-sm leading-5 font-medium text-gray-500">
		          Financing Type
		        </dt>
		        <dd className="mt-1 text-sm leading-5 text-gray-900">
	        	{(hit.typeOfFinancing && Array.isArray(hit.typeOfFinancing)) ? (
	        		<span>{hit.typeOfFinancing.join(', ')}</span>
	        	) : <span /> }
		        </dd>
		      </div>
		      <div className="sm:col-span-1">
		        <dt className="text-sm leading-5 font-medium text-gray-500">
		          Headquarters
		        </dt>
		        {(plan === 3 || plan === 30 || plan === 4) ? (
			        <dd className="mt-1 text-sm leading-5 text-gray-900">
			          <span>{hit.officeAddress1}</span><br />
			          {hit.officeAddress2 ? (<p><span>{hit.officeAddress2}</span><br /></p>) : <span />}
			          <span>{hit.officeCity}, </span>
			          <span>{hit.officeState}, </span>
			          <span>{hit.officeZip}</span><br />
			          <span>{hit.officeCountry}</span>
			        </dd>
			      ) : (
			      	<NavLink to="/pricing" className="text-sm leading-5 font-medium underline">
			      		<span>Upgrade to access</span>
			      	</NavLink>
			      ) }
		      </div>
		    </dl>
		  </div>

      <div>
        <div className="mx-4 mt-0 mb-6 sm:mx-6 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow-sm border border-gray-200 md:grid-cols-3">
          <div>
            <div className="px-4 py-5 sm:p-6">
              <dl>
                <dt className="text-sm leading-6 font-normal text-gray-500">
                  AUM
                </dt>
                <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                  <div className="flex items-baseline text-2xl leading-8 font-semibold text-gray-800">
                    {hit.capitalManaged === 0 ? "N/A" : hit.currency + hit.capitalManaged}
                  </div>
                </dd>
              </dl>
            </div>
          </div>
          <div className="border-t border-gray-200 md:border-0 md:border-l">
            <div className="px-4 py-5 sm:p-6">
              <dl>
                <dt className="text-sm leading-6 font-normal text-gray-500">
                  MIN INVESTMENT
                </dt>
                <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                  <div className="flex items-baseline text-2xl leading-8 font-semibold text-gray-800">
                    {hit.minInvestment === 0 ? "N/A" : hit.currency + hit.minInvestment}
                  </div>
                </dd>
              </dl>
            </div>
          </div>
          <div className="border-t border-gray-200 md:border-0 md:border-l">
            <div className="px-4 py-5 sm:p-6">
              <dl>
                <dt className="text-sm leading-6 font-normal text-gray-500">
                  MAX INVESTMENT
                </dt>
                <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                  <div className="flex items-baseline text-2xl leading-8 font-semibold text-gray-800">
                    {hit.maxInvestment === 0 ? "N/A" : hit.currency + hit.maxInvestment}
                  </div>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>

			<div className="px-4 py-5 sm:px-6">
		    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
		      <div className="sm:col-span-1">
		        <dt className="text-sm leading-5 font-medium text-gray-500">
		          Email address
		        </dt>
		        <dd className="mt-1 text-sm leading-5 text-gray-900">
		          <p>{hit.officeEmail}</p>
		        </dd>
		      </div>
		      <div className="sm:col-span-1">
		        <dt className="text-sm leading-5 font-medium text-gray-500">
		          Phone number
		        </dt>
		        <dd className="mt-1 text-sm leading-5 text-gray-900">
		          <p >{(plan === 3 || plan === 30 || plan === 4) ? hit.officePhone : "Upgrade to access"}</p>
		        </dd>
		      </div>
		      <div className="sm:col-span-1">
		        <dt className="text-sm leading-5 font-medium text-gray-500">
		          Office contact
		        </dt>
		        <dd className="mt-1 text-sm leading-5 text-gray-900">
		          <p>{hit.officeFirstName} {hit.officeLastName}</p>
		          {hit.officeWorkEmail ? (
			          <p className="flex">
									<svg className="h-5 w-5 text-gray-400 mr-2" viewBox="0 0 20 20" fill="currentColor">
									  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
									  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
									</svg>
									<span>{hit.officeWorkEmail}</span>
								</p>
		          ) : <span /> }
		          {hit.officeWorkPhone ? (
			          <p className="flex">
				          <svg className="h-5 w-5 text-gray-400 mr-2" viewBox="0 0 20 20" fill="currentColor">
									  <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
									</svg>
									<span>{(plan === 3 || plan === 30 || plan === 4) ? hit.officeWorkPhone : "Upgrade to access"}</span>
								</p>
		          ) : <span /> }
		        </dd>
		      </div>
		      <div className="sm:col-span-1">
		        <dt className="text-sm leading-5 font-medium text-gray-500">
		          Website
		        </dt>
		        <dd className="mt-1 text-sm leading-5 text-gray-900">
		          <p>{hit.officeWebsite}</p>
		        </dd>
		      </div>
		    </dl>
		  </div>

		  <div className="px-4 py-2 mb-2 sm:px-6">
		    <dl className="grid grid-cols-1 col-gap-4 row-gap-6 sm:grid-cols-2">
		      <div className="sm:col-span-2">
		        <dt className="text-sm leading-5 font-medium text-gray-500">
		          Geographic Preferences
		        </dt>
		        <dd className="mt-1 text-sm leading-5 text-gray-900">
	        	{(hit.geographicPreferences && Array.isArray(hit.geographicPreferences)) ? (
	        		<span>{hit.geographicPreferences.join(', ')}</span>
	        	) : <span /> }
	        	</dd>
		      </div>
		      <div className="sm:col-span-2">
		        <dt className="text-sm leading-5 font-medium text-gray-500">
		          Stage Preferences
		        </dt>
		        <dd className="mt-1 text-sm leading-5 text-gray-900">
	        	{(hit.stagePreferences && Array.isArray(hit.stagePreferences)) ? (
	        		<span>{hit.stagePreferences.join(', ')}</span>
	        	) : <span /> }
	        	</dd>
		      </div>
		      <div className="sm:col-span-2">
		        <dt className="text-sm leading-5 font-medium text-gray-500">
		          Industry Preferences
		        </dt>
		        <dd className="mt-1 text-sm leading-5 text-gray-900">
	        	{(hit.industryPreferences && Array.isArray(hit.industryPreferences)) ? (
	        		<span>{hit.industryPreferences.join(', ')}</span>
	        	) : <span /> }
	        	</dd>
		      </div>
		    </dl>
		  </div>
		</div>
	);
}

import React from 'react';
import { connectHits } from 'react-instantsearch-dom';
import AddAllToListButton from '../search/addAllToListButton';

const Hits = ({ hits, lists, status, plan, dataType }) => (
	<div>
	  <AddAllToListButton profiles={hits} lists={lists} status={status} plan={plan} dataType={dataType} />
	</div>
);

const CustomHits = connectHits(Hits);
export default CustomHits;

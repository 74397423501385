import React from 'react';
import Hero from './hero';
import Features from './features';
import HowItWorks from './howItWorks';
import WhatYouGet from './whatYouGet';
import Faq from './faq';
import Testimonials from './testimonials';
import Footer from './footer';
import { Helmet } from "react-helmet";

const Landing = (props) => (
  <div>
    <Helmet>
      <title>Investor Scout is an investor database of 37,000+ investors</title>
      <meta name="description" content="Investor Scout is a comprehensive investor database complete with around 40,000 investor emails to pitch your startup 🚀" />

      <meta itemprop="name" content="Investor Scout is an investor database of 37,000+ investors 🚀" />
      <meta itemprop="description" content="Investor Scout is a comprehensive investor database complete with around 40,000 investor emails to pitch your startup 🚀" />
      <meta itemprop="image" content="https://i.imgur.com/50Zupcn.png" />

      <meta property="og:url" content="https://investorscout.co" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Investor Scout is an investor database of 37,000+ investors 🚀" />
      <meta property="og:description" content="Investor Scout is a comprehensive investor database complete with around 40,000 investor emails to pitch your startup 🚀" />
      <meta property="og:image" content="https://i.imgur.com/50Zupcn.png" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Investor Scout is an investor database of 37,000+ investors 🚀" />
      <meta name="twitter:description" content="Investor Scout is a comprehensive investor database complete with around 40,000 investor emails to pitch your startup 🚀" />
      <meta name="twitter:image" content="https://i.imgur.com/50Zupcn.png" />

      <script type="text/javascript" src="https://load.fomo.com/api/v1/6drd7n4A3I20_l_e4M-Wjw/load.js" async></script>
    </Helmet>
    <Hero props={props} />
    <Features />
    <HowItWorks />
    <WhatYouGet />
    <Testimonials />
    <Faq />
    <Footer />
  </div>
)


export default Landing;

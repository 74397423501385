import React from 'react';
import { Switch, Route } from 'react-router-dom';
import FireflySubscription from './misc/FireflySubscription';
import { FirestoreCollection } from 'react-firestore';
import Loading from './misc/Loading';

import Dashnav from './Dashboard/dashNav';
import Landing from './Landing/main';
import Terms from './Landing/termsAndConditions';
import SignUp from './account/hookSignUp';
import SignIn from './account/hookSignIn';
import PasswordForgetPage from './account/PasswordForget';
import Pricing from './Landing/pricing';
import InvestorDashboard from './Dashboard/investorDash';
import HomeDashboard from './Dashboard/HomeDashboard';
import FundDashboard from './Dashboard/fundDash';
import Lists from './Lists/infiniteListIndex';
import List from './Lists/infiniteList';
import ListContact from './Lists/listContact';
import Account from './account/accountDash';

import Investors from './Investors/index';
import Profile from './Investors/profile';
import AppsumoSignup from './account/appsumoSignup';
import AppsumoUpgrade from './account/appsumoUpgrade';

import Error from './misc/Error';

const NavRoute = ({ exact, path, component: Component }) => (
  <Route exact={exact} path={path} render={(props) => (
    <div>
      <Dashnav path={path} />
      <FireflySubscription>
      {({isLoading, error, subscription, auth, status, plan}) => {
        if (isLoading) {
          return <Loading />
        }

        if (error) {
          return <Error error={error} />
        }

        if (!auth) {
          return <Component {...props} />
        }

        if (auth) {
          return (
            <FirestoreCollection
              path={`lists`}
              sort="createdOn:desc"
              filter={['createdBy', '==', auth.uid]}
            >
              { ({error, isLoading, data}) => {
                if (error) {
                  return <Error error={error} />
                }
                if (isLoading) {
                  return <Loading />
                }
                if (!isLoading) {
                  return <Component {...props} subscription={subscription} status={status} plan={plan} lists={data} auth={auth}/>
                }
              }}
            </FirestoreCollection>
          )
        }
      }}
      </FireflySubscription>
    </div>
  )}/>
)

const Routes = () => (
  <Route render={ ({location}) => (
    <Switch location={location}>
      {/*Routes are for landing page routes */}
      <Route exact path="/" component={Landing} />
      <Route exact path="/pricing" component={Pricing} />
      <Route exact path="/terms-and-conditions" component={Terms} />
      <Route path="/signup" component={SignUp} />
      <Route path="/signin" component={SignIn} />
      <Route path="/passwordforget" component={PasswordForgetPage} />
      <Route exact path="/investors" component={Investors} />
      <Route path="/investors/:slug" component={Profile} />
      <Route path="/appsumo" component={AppsumoSignup} />
      {/*NavRoute provides topnav for in-app routes */}
      <NavRoute exact path="/account" component={Account} />
      <NavRoute exact path="/dashboard" component={HomeDashboard} />
      <NavRoute exact path="/investors-dashboard" component={InvestorDashboard} />
      <NavRoute exact path="/funds-dashboard" component={FundDashboard} />
      <NavRoute exact path="/lists" component={Lists} />
      <NavRoute exact path="/list/:slug" component={List} />
      <NavRoute path="/list/:slug/:objectID" component={ListContact} />
      <NavRoute exact path="/appsumo-upgrade" component={AppsumoUpgrade} />

      <Route component={Error} />
    </Switch>
  )} />
);

export default Routes;

import React, { Component } from 'react';
import { FirestoreCollection } from 'react-firestore';
import { NavLink } from 'react-router-dom';
import { Helmet } from "react-helmet";

import Error from '../misc/Error';
import Loading from '../misc/Loading';
import Placeholder from '../../assets/placeholder.png';
import Cta from './cta';
import Footer from '../Landing/footer';


class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: this.props.location.state ? this.props.location.state.page : 1,
      objectId: this.props.location.state ? this.props.location.state.id : null,
    }
  }

  render() {
    return (
      <div>
        <FirestoreCollection
          path={'investors'}
          filter={[['slug', '==', this.props.match.params.slug], ['objectID', '==', this.state.objectId]]}
          render={({ error, isLoading, data }) => {
            if (isLoading) {
              return <Loading />
            }
            if (error) {
              return <Error error={error} />
            }
            if (!data[0] || !data[0].name) {
              return <p className="loading has-text-centered is-centered"><span role="img" aria-label="construction">🚧</span> Page not found <span role="img" aria-label="construction">🚧</span></p>
            }
            return (
              <div>
                <Helmet>
                  <title>{data[0].name + " investor email and phone number " + (data[0].company ? data[0]['company.name'] : "") + " – Investor Scout"}</title>
                  <meta name="description" content={"Find " + data[0].name +"'s email, phone number, and contact info in Investor Scout's database of 37,000+ investors"} />

                  <meta itemprop="name" content={data[0].name + " investor email and phone number " + (data[0].has_company ? data[0]['company.name'] : "") + " – Investor Scout"} />
                  <meta itemprop="description" content={"Find " + data[0].name +"'s email, phone number, and contact info in Investor Scout's database of 37,000+ investors"} />
                  <meta itemprop="image" content={data[0].avatar ? data[0].avatar : "https://i.imgur.com/uOpUy46.png" } alt={data[0].name + " investor photo"} />

                  <meta property="og:url" content={"https://investorscout.co/" + `investors/`+ this.props.match.params.slug} />
                  <meta property="og:type" content="website" />
                  <meta property="og:title" content={data[0].name + " investor email and phone number " + (data[0].has_company ? data[0]['company.name'] : "") + " – Investor Scout"} />
                  <meta property="og:description" content={"Find " + data[0].name +"'s email, phone number, and contact info in Investor Scout's database of 37,000+ investors"} />
                  <meta property="og:image" content={data[0].avatar ? data[0].avatar : "https://i.imgur.com/uOpUy46.png" } alt={data[0].name + " investor photo"} />

                  <meta name="twitter:card" content="summary_large_image" />
                  <meta name="twitter:title" content={data[0].name + " investor email and phone number " + (data[0].has_company ? data[0]['company.name'] : "") + " – Investor Scout"} />
                  <meta name="twitter:description" content={"Find " + data[0].name +"'s email, phone number, and contact info in Investor Scout's database of 37,000+ investors"} />
                  <meta name="twitter:image" content={data[0].avatar ? data[0].avatar : "https://i.imgur.com/uOpUy46.png" } alt={data[0].name + " investor photo"} />

                  <script type="text/javascript" src="https://load.fomo.com/api/v1/6drd7n4A3I20_l_e4M-Wjw/load.js" async></script>
                </Helmet>

                <Cta />

                <div className="container mx-auto mb-24">
                  <div className="py-5 border-b border-gray-200">
                    <NavLink
                      to={{
                        pathname: "/investors",
                        state: { page: this.state.currentPage }
                      }}
                      className="inline-flex items-center px-4 py-2 border border-gray-300 text-base leading-6 text-gray-600 font-medium rounded-md hover:text-gray-500 hover:bg-gray-100 transition duration-150 ease-in-out"
                    >
                      <svg className="h-5 w-5 text-gray-600 mr-1"  viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clipRule="evenodd" />
                      </svg>
                      <span>Back to investor directory</span>
                    </NavLink>
                  </div>

                  <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                    <div className="flex-1 flex flex-col p-8">
                      <img className="w-32 h-32 flex-shrink-0 mx-auto bg-black rounded-full" src={data[0].avatar ? data[0].avatar : Placeholder} alt="" />
                      <h3 className="mt-6 text-gray-900 text-lg leading-5 font-large text-center">{data[0].name}</h3>
                      <dl className="mt-1 flex-grow flex flex-col justify-between text-center">
                        <dt className="sr-only">Title</dt>
                        <dd className="text-gray-500 text-md leading-5 text-center">
                          <span>
                            {(data[0].has_company && typeof data[0].company === "string") ? 
                            data[0].company : (data[0].has_company && typeof data[0].company === "object") ?
                            data[0].company["name"] : "Investor" }
                          </span>
                        </dd>
                        <dt className="sr-only">Location</dt>
                        <dd className="mt-3">
                          <span className="px-2 py-1 text-teal-800 text-xs leading-4 font-medium bg-teal-100 rounded-full">{data[0].city}</span>
                        </dd>
                      </dl>
                    </div>

                    <div className="px-4 py-5 lg:px-40 sm:px-6">
                      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3 text-center">
                        <div className="sm:col-span-1">
                          <div>
                          {(data[0].linkedin && data[0].linkedin.includes("://")) ? (
                            <a href={'https://www.linkedin.com/in/' + data[0].linkedin.substr(data[0].linkedin.lastIndexOf('/') + 1)} target="_blank" rel="noopener noreferrer" className="inline-flex text-md leading-5 font-medium text-indigo-600">
                              LinkedIn
                              <svg className="ml-1 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                              </svg>
                            </a>) : (data[0].has_linkedin) ? (
                            <a href={'https://www.linkedin.com/in/' + data[0].linkedin} target="_blank" rel="noopener noreferrer" className="inline-flex text-md leading-5 font-medium text-indigo-600">
                              LinkedIn
                              <svg className="ml-1 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                              </svg>
                            </a>) : <span /> }
                          </div>
                        </div>
                        <div className="sm:col-span-1">
                          <div>
                          {(data[0].angellist && data[0].angellist.includes("://")) ? (
                            <a href={'https://www.angel.co/' + data[0].angellist.substr(data[0].angellist.lastIndexOf('/') + 1)} className="inline-flex text-md leading-5 font-medium text-indigo-600" target="_blank" rel="noopener noreferrer">
                              AngelList
                              <svg className="ml-1 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                              </svg>
                            </a>) : (data[0].has_angellist) ? (
                            <a href={'https://www.angel.co/' + data[0].angellist} target="_blank" rel="noopener noreferrer" className="inline-flex text-md leading-5 font-medium text-indigo-600">
                              AngelList
                              <svg className="ml-1 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                              </svg>
                            </a>) : <span /> }
                          </div>
                        </div>
                        <div className="sm:col-span-1">
                          <div>
                          {(data[0].twitter && data[0].twitter.includes("://")) ? (
                            <a href={'https://www.twitter.com/' + data[0].twitter.substr(data[0].twitter.lastIndexOf('/') + 1)} className="inline-flex text-md leading-5 font-medium text-indigo-600" target="_blank" rel="noopener noreferrer">
                              Twitter
                              <svg className="ml-1 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                              </svg>
                            </a>) : (data[0].twitter && data[0].twitter.indexOf("http://") === 0 && data[0].twitter.indexOf("https://") === 0) ? (
                            <a href={'https://www.twitter.com/' + data[0].twitter} target="_blank" rel="noopener noreferrer" className="inline-flex text-md leading-5 font-medium text-indigo-600">
                              Twitter
                              <svg className="ml-1 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                              </svg>
                            </a>) : <span /> }
                          </div>
                        </div>
                      </dl>
                    </div>

                    <div className="px-4 py-5 lg:px-40 sm:px-6">
                      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3 text-center">
                        <div className="sm:col-span-1">
                          <dt className="text-md leading-5 font-medium text-gray-600">
                            Email address
                          </dt>
                          <dd>
                            <NavLink to="/pricing" className="inline-flex items-center mt-2 px-2 py-1 rounded-md text-sm font-medium leading-5 bg-indigo-100 text-indigo-800">
                              <svg className="-ml-0.5 mr-1 h-2 w-2 text-indigo-400" fill="currentColor" viewBox="0 0 8 8">
                                <circle cx="4" cy="4" r="3" />
                              </svg>
                              Premium data
                            </NavLink>                          
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-md leading-5 font-medium text-gray-600">
                            Phone number
                          </dt>
                          <dd>
                            <NavLink to="/pricing" className="inline-flex items-center mt-2 px-2 py-1 rounded-md text-sm font-medium leading-5 bg-indigo-100 text-indigo-800">
                              <svg className="-ml-0.5 mr-1 h-2 w-2 text-indigo-400" fill="currentColor" viewBox="0 0 8 8">
                                <circle cx="4" cy="4" r="3" />
                              </svg>
                              Premium data
                            </NavLink>
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-md leading-5 font-medium text-gray-600">
                            Website
                          </dt>
                          <dd>
                            <NavLink to="/pricing" className="inline-flex items-center mt-2 px-2 py-1 rounded-md text-sm font-medium leading-5 bg-indigo-100 text-indigo-800">
                              <svg className="-ml-0.5 mr-1 h-2 w-2 text-indigo-400" fill="currentColor" viewBox="0 0 8 8">
                                <circle cx="4" cy="4" r="3" />
                              </svg>
                              Premium data
                            </NavLink>                            
                          </dd>
                        </div>
                      </dl>
                    </div>

                    <div className="px-4 py-5 lg:px-40 sm:px-6 my-16">
                      <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-1">
                        <div className="sm:col-span-1">
                          <dt className="text-md leading-5 font-medium text-gray-500 text-center">
                            {(data[0].has_markets && Array.isArray(data[0].markets)) ? "Industry Preferences" : ""}
                          </dt>
                          <dd>
                            {(data[0].has_markets && Array.isArray(data[0].markets)) ? (
                              <p className="mt-1 text-sm leading-10 text-gray-800 text-left">
                                {data[0].markets.join(', ')}
                              </p>
                            ) : <span /> }
                          </dd>                       
                        </div>
                      </dl>
                    </div>

                    <div className="px-4 py-5 lg:px-40 sm:px-6 my-16">
                      <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-1 text-center">
                        <div className="sm:col-span-1">
                          <dt className="text-md leading-5 font-medium text-gray-500">
                            {(data[0].has_investments && Array.isArray(data[0].investments)) ? "Companies invested" : ""}
                          </dt>
                          <dd className="mt-1 text-sm leading-5 text-gray-900">
                            {(data[0].has_investments && Array.isArray(data[0].investments)) ? (
                              data[0].investments.map((investment, index) =>
                                <a key={index} className="inline-flex mx-1 items-center mt-2 px-2 py-1 rounded-md text-sm font-medium leading-5 bg-yellow-100 text-yellow-800" href={investment["link"]} target="_blank" rel="noopener noreferrer">
                                  {investment["name"]}
                                </a>
                              )
                            ) : <span /> }
                          </dd>                       
                        </div>
                      </dl>
                    </div>
                  </div>
                </div>

                <Footer />
              </div>
            );
          }}
        />
      </div>
    );
  };
}

export default Profile;

import Firebase from 'firebase/app';

const deleteList = listId => {
  return Firebase.firestore()
    .collection('lists')
    .doc(listId)
		.delete().then( () => {
	    console.log("Document successfully deleted!");
		}).catch(function(error) {
	    console.error("Error removing document: ", error);
		});
}

export default deleteList;

import styled from 'styled-components'

const AppWrapper = styled.div`
  background-color: #f8fcff;
  min-height: 100vh;
`
const Header = styled.div`
  padding: 2rem 0;
`
const PageContainer = styled.div`
  position: relative;
`
const Page = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
`

export {
  AppWrapper,
  Header,
  PageContainer,
  Page,
}

import Firebase from 'firebase/app';
import { prepareDocForUpdate } from './helpers/firestoreHelpers';

const updateList = (listId, values) => {
  return Firebase.firestore()
    .collection('lists')
    .doc(listId)
    .update(prepareDocForUpdate(values))
    .catch( error => {
      alert(`Whoops, couldn't edit the list: ${error.message}`)
    })
}

export default updateList;

import Firebase from 'firebase/app';

const clearList = async listId => {
	var docRef = Firebase.firestore().collection("lists").doc(listId);

	await clearEntries(listId);
	await updateIndex(docRef);
};

const clearEntries = listId => {
	console.log("Clearing entries")

  return Firebase.firestore()
    .collection(`lists/${listId}/entries`)
	  .get()
	  .then(snap => {
	    snap.docs.map(doc => doc.ref.delete());
	  })
    .catch( error => {
      alert(`Whoops, couldn't clear the list: ${error.message}`)
    })
};

const updateIndex = docRef => {
	console.log("Entering updateIndex function");

	return docRef
	.update({
		fundIndex: [],
		investorIndex: [],
		updatedOn: Firebase.firestore.Timestamp.now(),
	})
  .catch( error => {
    alert(`Whoops, couldn't clear index: ${error.message}`)
  })
};

export default clearList;

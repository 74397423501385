import React from 'react';
import './index.css';
import {
  AppWrapper,
} from '../../styles/layout';

const Layout = ({children}) => (
  <AppWrapper>
    {children}
  </AppWrapper>
)

export default Layout;

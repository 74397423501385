import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Moment from 'react-moment';

import ListHookForm from '../Dashboard/listHookForm';
import EmptyListPlaceholder from './emptyListPlaceholder';
import Transition from '../../styles/Transition.js';

export default function Lists({ status, plan, lists }) {

  const [modalOn, setModal] = useState(false);

	return(
		<div>
			<div className={`${modalOn ? 'block z-10' : 'hidden'} fixed inset-0 overflow-y-auto`}>
			  <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
	        <Transition
	          show={modalOn}
	          enter="ease-out duration-300"
	          enterFrom="opacity-0"
	          enterTo="opacity-100"
	          leave="ease-in duration-200"
	          leaveFrom="opacity-100"
	          leaveTo="opacity-0"
	        >
				    <div className="fixed inset-0 transition-opacity">
				      <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
				    </div>
				  </Transition>

			    <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
	        <Transition
	          show={modalOn}
	          enter="ease-out duration-300"
	          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
	          enterTo="opacity-100 translate-y-0 sm:scale-100"
	          leave="ease-in duration-200"
	          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
	          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
	        >
	        	<ListHookForm activateModal={setModal} />
			    </Transition>
			  </div>
			</div>
			<div className="max-w-7xl py-10 mx-4 lg:mx-40 px-4 sm:px-0 lg:px-0">
				<div className="pb-5 mb-5 space-y-3 sm:flex sm:items-center sm:justify-between sm:space-x-4 sm:space-y-0">
				  <h3 className="text-lg leading-6 font-bold text-gray-900">
				    Your Lists
				  </h3>
				  <div>
				    <span className="shadow-sm rounded-md">
			      { (status === "active" && (plan === 3 || plan === 4)) ? (
			      	<button onClick={() => setModal(!modalOn)} type="button" className="ml-3 text-base bg-investor-blue hover:opacity-75 text-white font-semibold py-2 px-4 border border-gray-200 rounded-lg shadow-sm transition ease-in-out duration-150 inline-flex items-center">
                <svg className="opacity-50 fill-current outline-current mr-2 h-5 w-5 inline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M2 6a2 2 0 012-2h5l2 2h5a2 2 0 012 2v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" />
                  <path stroke="#003dff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 11h4m-2-2v4" />
                </svg>
								<span>New list</span>
				      </button>
				    ) : (
			      	<NavLink to="/pricing" className="ml-3 text-base bg-investor-blue hover:opacity-75 text-white font-semibold py-2 px-4 border border-gray-200 rounded-lg shadow-sm transition ease-in-out duration-150 inline-flex items-center">
                <svg className="opacity-50 fill-current outline-current mr-2 h-5 w-5 inline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M2 6a2 2 0 012-2h5l2 2h5a2 2 0 012 2v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" />
                  <path stroke="#003dff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 11h4m-2-2v4" />
                </svg>
               	<span>New list</span>
                <svg className="ml-2 h-5 w-5 flex" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#fff">
                  <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
                </svg>
				      </NavLink>
				    ) }
				    </span>
				  </div>
				</div>
				{ (status !== "active" || plan === 1 || plan === 10) ? (
					<EmptyListPlaceholder />
				) : (lists.length < 1 && (plan === 3 || plan === 30 || plan === 4)) ? (
				  <div className="rounded-md bg-orange-100 p-4">
				    <div className="flex">
				      <div className="flex-shrink-0">
				        <svg className="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
				          <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
				        </svg>
				      </div>
				      <div className="ml-3">
				        <h3 className="text-sm leading-5 font-medium text-yellow-800">
				          No list yet!
				        </h3>
				        <div className="mt-2 text-sm leading-5 text-yellow-700">
				          <p>
				            Create a list now to get started!
				          </p>
				        </div>
				      </div>
				    </div>
				  </div>
				) : (
					<div className="border border-gray-200 shadow-sm bg-white rounded-lg">
					  <ul>
					  	{lists.map((list, index) => (
					    <li key={index} className="border-b border-gray-200">
					      <NavLink to={`list/${list.slug}`} className="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out">
					        <div className="flex items-center px-4 py-4 sm:px-6">
					          <div className="min-w-0 flex-1 flex items-center">
											<svg className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
											  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
											</svg>
					            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
					              <div>
					                <div className="text-sm leading-5 font-bold text-investor-blue truncate">{list.name}</div>
					                <div className="mt-2 flex items-center text-sm leading-5 text-gray-500">
					                  <span className="truncate">{list.description ? list.description : "No description"}</span>
					                </div>
					              </div>
					              <div className="hidden md:block">
					                <div>
					                  <div className="text-sm leading-5 text-gray-900">
					                  	<span>{(list.fundIndex || list.investorIndex) ? `${(list.fundIndex ? list.fundIndex.length : 0) + (list.investorIndex ? list.investorIndex.length : 0)} contact(s)` : "No contacts yet"}</span>
					                  </div>
					                  <div className="mt-2 flex items-center text-sm leading-5 text-gray-500">
					                    <span>Last updated&nbsp;</span>
					                    <Moment fromNow ago>{list.updatedOn ? list.updatedOn.toDate() : list.createdOn.toDate()}</Moment><span>&nbsp;ago</span>
					                  </div>
					                </div>
					              </div>
					            </div>
					          </div>
					          <div>
					            <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
					              <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
					            </svg>
					          </div>
					        </div>
					      </NavLink>
					    </li>
					    ))}
					  </ul>
					</div>
				) }
			</div>
		</div>
	)
}
